import { Map, View } from "ol";
import { fromLonLat, toLonLat } from "ol/proj";
import { ScaleLine } from "ol/control";
import Zoom from 'ol/control/Zoom.js';

const en = [6.4483, 7.5139];
const center = fromLonLat(en);

const map = new Map({
  target: "map",
  view: new View({
    center: center,
    zoom: 7,
  }),
});

map.getControls().forEach(function(control) {
  if (control instanceof Zoom) {
    map.removeControl(control);
  }
});

var zoom = new Zoom({
  className: 'my-zoom1',
});

map.addControl(zoom);

map.addControl(new ScaleLine());

export default map;
