import map from "./map";
import { Vector as VectorSource, Stamen as SourceStamen, XYZ } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Style, Stroke, Fill, RegularShape } from "ol/style";
import CircleStyle from "ol/style/Circle";
import GeoJSON from "ol/format/GeoJSON";
import { geoserver_url } from "../config";
import { DFT_CQL_FILTER, DF_CQL_FILTER } from "./layer";

let Dft_Cql_Filter =
  DFT_CQL_FILTER == "" ? "" : "&cql_filter=" + DFT_CQL_FILTER;

let Df_Cql_Filter = DFT_CQL_FILTER == "" ? "" : "&cql_filter=" + DF_CQL_FILTER;

var Show_clicked_layer_Source = new VectorSource();

var Show_clicked_layer = new VectorLayer({
  visible: "true",
  source: Show_clicked_layer_Source,
  style: function (feature, resolution) {
    // console.log(feature);
    if (feature.getGeometry().getType() == "Polygon") {
      // Define the style for the polygon
      const polygonStyle = new Style({
        fill: new Fill({
          color: "transparent", // Fill color with opacity
        }),
        stroke: new Stroke({
          color: "orange", // Stroke color with opacity
          width: 4, // Stroke width
        }),
      });
      return polygonStyle;
    } else if (feature.getGeometry().getType() == "LineString") {
      // Define the style for the line
      const lineStyle = new Style({
        stroke: new Stroke({
          color: "blue", // Stroke color
          width: 2, // Stroke width
          lineDash: [5, 5], // Line dash pattern [dash, gap]
        }),
      });
      return lineStyle;
    } else if (feature.getProperties().hasOwnProperty("consumer_no")) {
      const pointStyle = new Style({
        image: new CircleStyle({
          radius: 9, // Adjust the radius of the circle
          fill: new Fill({
            color: "transparent", // Set the fill color
          }),
          stroke: new Stroke({
            color: "orange", // Set the border color
            width: 4, // Set the border width
          }),
        }),
      });
      return pointStyle;
    } else {
      const squareStyle = new Style({
        image: new RegularShape({
          fill: new Fill({
            color: "transparent", // Set the fill color (blue with 40% opacity)
          }),
          stroke: new Stroke({
            color: "red", // Set the border color to blue
            width: 2, // Set the border width
          }),
          points: 4, // Number of points in the regular shape (4 for a square)
          radius: 20, // Radius of the regular shape
          angle: 150, // Rotation angle in radians
        }),
      });
      return squareStyle;
    }
  },
});

map.addLayer(Show_clicked_layer);

var Label_Button = document.querySelectorAll(".Layer_Lable");
var Data_Button = document.getElementById("Show_Data_Button");
var Conatiner_Div = document.getElementById("Table_Data_Container");
var Arrow_icon = document.getElementById("Arrow_Icon");
var Selected_Layer_Div = document.getElementById("Show_Selected_Layer");

// Array to keep track of checkboxes
const checkboxes = [];

let clickedLayer = null; // Global variable to store the clicked layer

var geoserver_workspace = "new_gis_testing_workspace:";

let Data_Button_Flag = false;

function Return_Layer_Name(Layer_Name) {
  let Layer_Title = null;

  if (Layer_Name == "injection_substation") {
    Layer_Title = "INJECTION S/S";
  } else if (Layer_Name == "feeder_line") {
    Layer_Title = "FEEDER";
  } else if (Layer_Name == "ht_pole") {
    Layer_Title = "HT POLE";
  } else if (Layer_Name == "dss") {
    Layer_Title = "DSS";
  } else if (Layer_Name == "lt_pole") {
    Layer_Title = "LT POLE";
  } else if (Layer_Name == "lt_pole_connecting_lines") {
    Layer_Title = "LT POLE LINE";
  } else if (Layer_Name == "matched_db") {
    Layer_Title = "DIRECT CONSUMER";
  } else if (Layer_Name == "rural_consumers") {
    Layer_Title = "RURAL CONSUMER";
  } else if (Layer_Name == "indirect_consumer") {
    Layer_Title = "INDIRECT CONSUMER";
  } else if (Layer_Name == "direct_to_indirect_connection") {
    Layer_Title = "D2I";
  } else if (Layer_Name == "direct_to_rural_connection") {
    Layer_Title = "D2R";
  }

  // console.log(Layer_Title);
  return Layer_Title;
}

function createTable(data, currentPage, recordsPerPage, clickedLayer) {
  // console.log(clickedLayer);
  let features = data.features;
  // Check if features is defined and not empty
  if (!features || features.length === 0) {
    console.error("No features provided.");
    return;
  }

  // Extract properties from each feature
  const propertiesArray = features.map((data) => data.properties);

  // Calculate the serial number for the first record on the current page
  const startSerialNumber = (currentPage - 1) * recordsPerPage + 1;

  // Get the table container
  const tableContainer = document.getElementById("Table_Data_body");

  // Create a table element
  const table = document.createElement("table");
  table.id = "Table_div";
  table.classList.add("table", "table-striped-columns", "table-fixed");

  // Create table header
  const headerRow = document.createElement("tr");
  headerRow.classList.add("sticky-top");
  headerRow.style.top = "-10px";
  headerRow.style.color = "white";
  headerRow.style.backgroundColor = "black";
  headerRow.innerHTML = "<th>Sr No</th><th>Select</th>"; // Add Serial No and Select columns
  for (const key in propertiesArray[0]) {
    if (propertiesArray[0].hasOwnProperty(key)) {
      const headerCell = document.createElement("th");
      headerCell.textContent = key;
      headerRow.appendChild(headerCell);
    }
  }
  table.appendChild(headerRow);

  // Create table rows
  for (let i = 0; i < features.length; i++) {
    const row = document.createElement("tr");

    // Add Serial No cell
    const serialNumberCell = document.createElement("td");
    serialNumberCell.textContent = startSerialNumber + i;
    row.appendChild(serialNumberCell);

    // Add checkbox cell
    const checkboxCell = document.createElement("td");
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkboxCell.appendChild(checkbox);
    row.appendChild(checkboxCell);

    // Capture the current feature in a closure
    const currentFeature = features[i];

    // Add event listener to the checkbox
    checkbox.addEventListener("change", function () {
      if (this.checked) {
        // Uncheck all other checkboxes
        checkboxes.forEach((cb) => {
          if (cb !== checkbox) {
            cb.checked = false;
          }
        });

        var Get_Layer_Title = Return_Layer_Name(clickedLayer);
        // console.log(Get_Layer_Title);

        var layers = map.getLayers().getArray();

        for (var i = 0; i < layers.length; i++) {
          if (layers[i].get("title") === Get_Layer_Title) {
            var layer_visible = layers[i].getVisible();
            if (layer_visible == true) {
              // console.log("Layer is Visible");
              // Add the feature to the source
              Show_clicked_layer_Source.clear();
              Show_clicked_layer_Source.addFeature(
                new GeoJSON().readFeature(currentFeature, {
                  dataProjection: "EPSG:4326",
                  featureProjection: "EPSG:3857",
                })
              );
              map.getView().fit(Show_clicked_layer_Source.getExtent(), map.getSize());
              map.getView().setZoom("18");
            } else {
              // console.log("Layer Is Not Visible");
              $.notify(`${Get_Layer_Title} Layer Is Not Selected`, {
                color: "#fff",
                background: "#D44950",
                delay: 2000,
              });
            }
          }
        }
      } else {
        // If the checkbox is unchecked, remove the feature from the source
        Show_clicked_layer_Source.clear();
      }
    });

    // Add the checkbox to an array for easy access
    checkboxes.push(checkbox);

    // Add other cells
    for (const key in propertiesArray[i]) {
      if (propertiesArray[i].hasOwnProperty(key)) {
        const cell = document.createElement("td");
        cell.classList.add("text-nowrap");
        cell.textContent = propertiesArray[i][key];
        row.appendChild(cell);
      }
    }
    table.appendChild(row);
  }

  tableContainer.innerHTML = "";
  // Append the table to the container
  tableContainer.appendChild(table);
}

function Label_name_to_layer_name(name) {
  let Label_name = name;
  let Layer_name = null;

  if (Label_name == "INJECTION S/S") {
    Layer_name = "injection_substation";
  } else if (Label_name == "FEEDER") {
    Layer_name = "feeder_line";
  } else if (Label_name == "HT POLE") {
    Layer_name = "ht_pole";
  } else if (Label_name == "DSS") {
    Layer_name = "dss";
  } else if (Label_name == "LT POLE") {
    Layer_name = "lt_pole";
  } else if (Label_name == "DIRECT CONSUMER") {
    Layer_name = "matched_db";
  } else if (Label_name == "RURAL CONSUMER") {
    Layer_name = "rural_consumers";
  } else if (Label_name == "INDIRECT CONSUMER") {
    Layer_name = "indirect_consumer";
  } else if (Label_name == "LT POLE LINE") {
    Layer_name = "lt_pole_connecting_lines";
  } else if (Label_name == "D2I") {
    Layer_name = "direct_to_indirect_connection";
  } else if (Label_name == "D2R") {
    Layer_name = "direct_to_rural_connection";
  }

  // console.log(Layer_name);
  return Layer_name;
}

function updatePagination(currentPage) {
  // console.log(typeof String(currentPage));
  let WFS_URL = Get_WFS_URL(
    geoserver_workspace,
    clickedLayer,
    String(currentPage),
    Get_CQL_Filter(clickedLayer)
  );

  // console.log(WFS_URL,"Updatepagination");

  $.ajax({
    type: "GET",
    dataType: "json",
    url: WFS_URL,
    beforeSend: function () {
      document.getElementById("Table_Data_body").classList.add("spinner");
    },
  }).done(function (data) {
    document.getElementById("Table_Data_body").classList.remove("spinner");
    let All_Features = data.features;
    let Total_Features = data.totalFeatures;
    let Total_pages = parseFloat(Total_Features) / 10;

    createTable(data, currentPage, "10", clickedLayer);
    createPaginationButtons(String(Total_pages.toFixed()), currentPage);
  });
}

function createPaginationButtons(totalPages, currentPage) {
  var paginationContainer = document.getElementById("Pagination_Div");
  paginationContainer.innerHTML = "";

  // Define how many buttons you want to display at a time
  var maxButtons = 10;

  // Calculate the start and end buttons based on the current page
  var startButton = Math.max(1, currentPage - Math.floor(maxButtons / 2));
  var endButton = Math.min(startButton + maxButtons - 1, totalPages);

  // Adjust the start button to ensure that you always show maxButtons number of buttons
  startButton = Math.max(1, endButton - maxButtons + 1);

  // Create "Previous" button
  var previousButton = document.createElement("button");
  previousButton.classList.add("btn", "btn-outline-secondary");
  previousButton.innerHTML = "&laquo; Previous";
  previousButton.addEventListener("click", function () {
    if (currentPage > 1) {
      currentPage--;
      updatePagination(currentPage);
    }
  });
  paginationContainer.appendChild(previousButton);

  // Create numbered buttons
  if (startButton > 1) {
    var firstButton = document.createElement("button");
    firstButton.classList.add("btn", "btn-outline-secondary");
    firstButton.innerText = "1";
    firstButton.addEventListener("click", function () {
      currentPage = 1;
      updatePagination(currentPage);
    });
    paginationContainer.appendChild(firstButton);

    if (startButton > 2) {
      // Add ellipsis
      var ellipsis = document.createElement("button");
      ellipsis.classList.add("btn", "btn-outline-secondary");
      ellipsis.innerHTML = "...";
      ellipsis.addEventListener("click", function () {
        // Add logic for ellipsis click if needed
      });
      paginationContainer.appendChild(ellipsis);
    }
  }

  for (var i = startButton; i <= endButton; i++) {
    var numberButton = document.createElement("button");
    numberButton.classList.add("btn", "btn-outline-secondary");
    numberButton.innerText = i;
    numberButton.addEventListener("click", function () {
      currentPage = parseInt(this.innerText);
      updatePagination(currentPage);
    });
    paginationContainer.appendChild(numberButton);
  }

  if (endButton < totalPages) {
    if (endButton < totalPages - 1) {
      // Add ellipsis
      var ellipsis = document.createElement("button");
      ellipsis.classList.add("btn", "btn-outline-secondary");
      ellipsis.innerHTML = "...";
      ellipsis.addEventListener("click", function () {
        // Add logic for ellipsis click if needed
      });
      paginationContainer.appendChild(ellipsis);
    }

    var lastButton = document.createElement("button");
    lastButton.classList.add("btn", "btn-outline-secondary");
    lastButton.innerText = totalPages;
    lastButton.addEventListener("click", function () {
      currentPage = totalPages;
      updatePagination(currentPage);
    });
    paginationContainer.appendChild(lastButton);
  }

  // Create "Next" button
  var nextButton = document.createElement("button");
  nextButton.classList.add("btn", "btn-outline-secondary");
  nextButton.innerHTML = "Next &raquo;";
  nextButton.addEventListener("click", function () {
    if (currentPage < totalPages) {
      currentPage++;
      updatePagination(currentPage);
    }
  });
  paginationContainer.appendChild(nextButton);

  // Highlight the current page button
  var buttons = paginationContainer.getElementsByTagName("button");
  for (var j = 0; j < buttons.length; j++) {
    if (parseInt(buttons[j].innerText) === currentPage) {
      buttons[j].classList.add("active");
    } else {
      buttons[j].classList.remove("active");
    }
  }
}

function Get_WFS_URL(geoserver_workspace, Layer_name, currentpage, cql_filter) {
  // let Layer_cql_filter = cql_filter == "" ? "" : cql_filter
  // console.log(Layer_cql_filter);
  // let Currentpage_into_number = parseFloat(currentpage);
  // console.log(currentpage);
  // console.log(typeof Currentpage_into_number, "wfs url function");

  if (parseInt(currentpage) == 1) {
    let Wfs_url =
      geoserver_url +
      "geoserver/wfs?" +
      "service=WFS&" +
      "version=2.0.0&" +
      "request=GetFeature&" +
      "typeName=" +
      geoserver_workspace +
      Layer_name +
      "&" +
      "outputFormat=application/json&" +
      "srsname=EPSG:4326" +
      cql_filter +
      "&sortBy=t_district_id+ASC" +
      "&startIndex=0" +
      "&count=10";

    // console.log(Wfs_url);
    return Wfs_url;
  } else {
    let Startindex = parseInt(currentpage) - 1;
    // console.log(Startindex,"starindex");

    let Wfs_url =
      geoserver_url +
      "geoserver/wfs?" +
      "service=WFS&" +
      "version=2.0.0&" +
      "request=GetFeature&" +
      "typeName=" +
      geoserver_workspace +
      Layer_name +
      "&" +
      "outputFormat=application/json&" +
      "srsname=EPSG:4326" +
      cql_filter +
      "&sortBy=t_district_id+ASC" +
      "&startIndex=" +
      Startindex +
      "0" +
      "&count=10";

    // console.log(Wfs_url);
    return Wfs_url;
  }
}

function Get_CQL_Filter(layer_name) {
  let CQL_Filter = null;

  if (
    layer_name == "injection_substation" ||
    layer_name == "feeder_line" ||
    layer_name == "ht_pole"
  ) {
    CQL_Filter = Df_Cql_Filter;
    // console.log(Df_Cql_Filter);
    return CQL_Filter;
  } else {
    CQL_Filter = Dft_Cql_Filter;
    // console.log(Dft_Cql_Filter);
    return CQL_Filter;
  }
}

function handleLabelClick(event) {
  Show_clicked_layer_Source.clear();
  let Label_name = event.target.textContent.trim();
  // let Label = event.target
  // console.log(Label_name);
  let Layer_name = Label_name_to_layer_name(Label_name);

  Selected_Layer_Div.innerHTML = "";
  // Selected_Layer_Div.innerHTML = "Selected Layer : " + Label_name;

  clickedLayer = "";
  clickedLayer = Layer_name;

  let WFS_URL = Get_WFS_URL(
    geoserver_workspace,
    clickedLayer,
    "1",
    Get_CQL_Filter(Layer_name)
  );

  $.ajax({
    type: "GET",
    dataType: "json",
    url: WFS_URL,
    beforeSend: function () {
      document.getElementById("Table_Data_body").classList.add("spinner");
    },
  }).done(function (data) {
    document.getElementById("Table_Data_body").classList.remove("spinner");
    let All_Features = data.features;
    if (All_Features != "") {
      document.getElementById("Show_Selected_Layer").innerHTML = "";
      let Total_Features = data.totalFeatures;
      let Total_pages = parseFloat(Total_Features) / 10;

      createTable(data, "1", "10", clickedLayer);
      createPaginationButtons(String(Total_pages.toFixed()), "1");
    } else {
      document.getElementById("Table_Data_body").innerHTML = "";
      document.getElementById("Pagination_Div").innerHTML = "";
      document.getElementById("Show_Selected_Layer").innerHTML =
        " No Data Found ";
    }
  });
}

Label_Button.forEach((label) => {
  label.addEventListener("click", handleLabelClick);
});

Data_Button.addEventListener("click", function () {
  if (!Data_Button_Flag) {
    Data_Button_Flag = true;
  } else {
    Data_Button_Flag = false;
  }

  if (Data_Button_Flag) {
    Data_Button.style.bottom = "49%";
    Arrow_icon.style.transform = "rotate(180deg)";
    Conatiner_Div.style.transition = "1s";
    Conatiner_Div.style.display = "block";
  } else {
    Data_Button.style.bottom = "-1%";
    Arrow_icon.style.transform = "rotate(0deg)";
    Conatiner_Div.style.display = "none";
  }
});

const Color = "rgb(199 213 219)";
let previousLabel = null;

Label_Button.forEach((label) => {
  label.addEventListener("click", () => {
    // Remove background color from the previous clicked label
    if (previousLabel) {
      previousLabel.style.backgroundColor = "";
    }

    // Set orange background color for the clicked label
    label.style.backgroundColor = Color;

    // Update the previousLabel to the current label
    previousLabel = label;
  });
});

export { Show_clicked_layer_Source };
