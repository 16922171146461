import map from "./map";
import { osm, topo, Satellite, dss, direct_consumer_layer, direct_source, dss_source } from "./layer";
import { global } from "./customerdetails";
import {
  click_point_rural_source,
  click_point_indirect_source,
  highlight_source,
} from "./bills";


const Full_Screen_Button_Style = document.querySelector(
  'button[title="Toggle full-screen"]'
);
const panelButton = document.querySelector(".panel_button");
const panelCard = document.querySelector(".panel_card");
const Osm_layer = document.getElementById("osm_layer");
const Topo_layer = document.getElementById("topo_layer");
const Satellite_layer = document.getElementById("satellite_layer");
const layerswitcher_button = document.getElementById("Layer_Switcher_button");
const layerswitcher_closer = document.getElementById("Layer_Switcher_Closer");
const Hide_navbar_button = document.querySelector(".circular-button");
const Tool_Box_Legend = document.getElementById("Tool_box_legend");
const dss_img = document.getElementById("legend");
const direct_img = document.getElementById("direct-legend");

var layerswitcher_flag = false;
var layerswitcher_button_click_flag = false;
var Tool_Box_Lenged_Flag = true;
var coustomer_details_button = document.getElementById("leftArrow");
var osm_layer_visible = osm.getVisible();

let panel_flag = false;
let Hide_navbar_flag = false;

Full_Screen_Button_Style.style.width = "35px";
Full_Screen_Button_Style.style.height = "36px";

layerswitcher_button.addEventListener("click", function () {
  if (!layerswitcher_button_click_flag) {
    layerswitcher_button_click_flag = true;
    layerswitcher_flag = true;
    document.getElementById("Tool_box_card").style.marginLeft = "275px";
    document.querySelector(".my-fullscreen1").style.transition = "0.5s";
    document.querySelector(".my-fullscreen1").style.marginLeft = "275px";
    document.querySelector(".panel_card").style.transition = "0.5s";
    document.querySelector(".panel_card").style.marginLeft = "275px";
  } else {
    layerswitcher_button_click_flag = false;
    layerswitcher_flag = false;
    document.getElementById("Tool_box_card").style.marginLeft = "0px";
    document.querySelector(".my-fullscreen1").style.transition = "0.5s";
    document.querySelector(".my-fullscreen1").style.marginLeft = "0px";
    document.querySelector(".panel_card").style.transition = "0.5s";
    document.querySelector(".panel_card").style.marginLeft = "0px";
  }
  // console.log(layerswitcher_button_click_flag);
});

map.on("click", function (evt) {
  if (layerswitcher_flag == true) {
    $(layerswitcher_closer).click();
    if (layerswitcher_button_click_flag) {
      layerswitcher_button_click_flag = false;
      layerswitcher_closer.addEventListener("change", function () {
        document.getElementById("Tool_box_card").style.marginLeft = "0px";
        document.querySelector(".my-fullscreen1").style.marginLeft = "0px";
        document.querySelector(".panel_card").style.transition = "0.5s";
        document.querySelector(".panel_card").style.marginLeft = "0px";
      });
    }
    // console.log(layerswitcher_button_click_flag);
    layerswitcher_flag = false;
  }
});

layerswitcher_closer.addEventListener("click", function () {
  if (layerswitcher_button_click_flag) {
    document.getElementById("Tool_box_card").style.marginLeft = "0px";
    document.querySelector(".my-fullscreen1").style.marginLeft = "0px";
    document.querySelector(".panel_card").style.transition = "0.5s";
    document.querySelector(".panel_card").style.marginLeft = "0px";
    layerswitcher_button_click_flag = false;
  }
});

coustomer_details_button.addEventListener("click", function () {
  if (layerswitcher_button_click_flag) {
    document.getElementById("Tool_box_card").style.marginLeft = "0px";
    document.querySelector(".my-fullscreen1").style.marginLeft = "0px";
    document.querySelector(".panel_card").style.transition = "0.5s";
    document.querySelector(".panel_card").style.marginLeft = "0px";
    layerswitcher_button_click_flag = false;
  }
});

if (osm_layer_visible) {
  $("#osm_layer").addClass("Panel_border");
  $("#satellite_layer").removeClass("Panel_border");
  $("#topo_layer").removeClass("Panel_border");
}

panelButton.addEventListener("click", function () {
  if (!panel_flag) {
    panel_flag = true;
    panelCard.style.transition = "1s";
    panelCard.style.display = "block";
  } else {
    panel_flag = false;
    panelCard.style.transition = "1s";
    panelCard.style.display = "none";
  }
});

Osm_layer.addEventListener("click", function () {
  $("#osm_layer").addClass("Panel_border");
  $("#satellite_layer").removeClass("Panel_border");
  $("#topo_layer").removeClass("Panel_border");
  osm.setVisible(true);
  topo.setVisible(false);
  Satellite.setVisible(false);
});

Topo_layer.addEventListener("click", function () {
  $("#topo_layer").addClass("Panel_border");
  $("#satellite_layer").removeClass("Panel_border");
  $("#osm_layer").removeClass("Panel_border");
  osm.setVisible(false);
  topo.setVisible(true);
  Satellite.setVisible(false);
});

Satellite_layer.addEventListener("click", function () {
  $("#satellite_layer").addClass("Panel_border");
  $("#topo_layer").removeClass("Panel_border");
  $("#osm_layer").removeClass("Panel_border");
  osm.setVisible(false);
  topo.setVisible(false);
  Satellite.setVisible(true);
});

Hide_navbar_button.addEventListener("click", function () {
  Hide_navbar_button.style.transform = "rotate(180deg)";
  if (!Hide_navbar_flag) {
    document.querySelector(".my-zoom1").style.right = "55px"
    document.querySelector("#direct-legend").style.right = "9%"
    Hide_navbar_button.style.right = "30px";
    document.querySelector(".New_navbar").style.transition = "0.5s"
    document.querySelector(".New_navbar").style.display = "flex";
    Hide_navbar_flag = true;
  } else {
    document.querySelector(".my-zoom1").style.right = ""
    document.querySelector("#direct-legend").style.right = ""
    Hide_navbar_button.style.transform = "";
    Hide_navbar_button.style.right = "";
    document.querySelector(".New_navbar").style.display = "";
    Hide_navbar_flag = false;
  }
});

map.on("click", function (evt) {
  if (panel_flag) {
    $(".panel_button").click();
  }
});

layerswitcher_closer.addEventListener("click", function () {
  if (panel_flag) {
    $(".panel_button").click();
  }
});

Tool_Box_Legend.addEventListener("click", function () {
  if (Tool_Box_Lenged_Flag) {
    Tool_Box_Lenged_Flag = false;
    Tool_Box_Legend.style.border = "0.5px solid white";
    Tool_Box_Legend.style.backgroundColor = "white";
    dss_img.src = "";
    direct_img.src = ""
  } else if (Tool_Box_Lenged_Flag == false) {
    Tool_Box_Lenged_Flag = true;
    Tool_Box_Legend.style.border = "0.5px solid #0e918c";
    Tool_Box_Legend.style.backgroundColor = "#0e918c";

    if (dss.getVisible()) {
      const dss_updateLegend = function (resolution) {
        const graphicUrl = dss_source.getLegendUrl(resolution);
        dss_img.src = graphicUrl;
      };
      const resolution = map.getView().getResolution();
      dss_updateLegend(resolution);
    } 

    if (direct_consumer_layer.getVisible()) {
      const direct_updateLegend = function (resolution) {
        const graphicUrl = direct_source.getLegendUrl(resolution);
        direct_img.src = graphicUrl;
      };
      const resolution1 = map.getView().getResolution();
      direct_updateLegend(resolution1);
    } 

    if (!dss.getVisible() && !direct_consumer_layer.getVisible()) {
      $.notify("Select DSS layer or Direct Consumer layer", {
        color: "#fff",
        background: "#D44950",
        delay: 3000,
      });
    }

  }
});
