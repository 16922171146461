import "./Gis_Screen_js/customerdetails";
import "./Gis_Screen_js/map";
import "./Gis_Screen_js/layer";
import "./Gis_Screen_js/charts";
import "./Gis_Screen_js/bills";
import "./Gis_Screen_js/cardoverlay";
import "./Gis_Screen_js/ol5-sidebar";
import "./Gis_Screen_js/count";
import "./Gis_Screen_js/EventListener"
import "./Gis_Screen_js/New_Layerswitcher_Listener"
import "./Gis_Screen_js/Layer_Listener"
import "./Gis_Screen_js/Label_Listener"
// import "./js/marketer_daily_ativity";
// import "./js/select";
// import "./js/detailsoverlay";
