var mylinechart = null;

function showBillAndPaymentChart(xaxis, paiddata, balancedata) {
  if (mylinechart != null) {
    mylinechart.destroy();
  }
  var speedCanvas = document.getElementById("speedChart");

  Chart.defaults.global.defaultFontFamily = "Lato";
  Chart.defaults.global.defaultFontSize = 10;

  var dataFirst = {
    label: "Paid",
    data: paiddata,
    lineTension: 0,
    fill: false,
    borderColor: "green",
  };

  var dataSecond = {
    label: "Bill Amount",
    data: balancedata,
    lineTension: 0,
    fill: false,
    borderColor: "red",
  };

  var speedData = {
    labels: xaxis,
    datasets: [dataFirst, dataSecond],
  };

  var chartOptions = {
    legend: {
      display: true,
      position: "top",
      labels: {
        // boxWidth: 10,
        usePointStyle: true,
        fontColor: "black",
      },
    },
    scales: {
      dataFirst: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      dataSecondy: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
    },
  };

  mylinechart = new Chart(speedCanvas, {
    type: "line",
    data: speedData,
    options: chartOptions,
  });
}

export { showBillAndPaymentChart, mylinechart };
