import map from "./map";
import OSM from "ol/source/OSM";
import { Vector as VectorSource, Stamen as SourceStamen, XYZ } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import TileWMS from "ol/source/TileWMS";
import GeoJSON from "ol/format/GeoJSON";
import Sidebar from "../Gis_Screen_js/ol5-sidebar";
import { FullScreen, defaults as defaultControls } from "ol/control";
import LayerSwitcherImage from "../lib/control/LayerSwitcherImage.js";
import GeoJSON from "ol/format/GeoJSON";
import WMSCapabilities from "ol/format/WMSCapabilities";
import { transformExtent } from "ol/proj";
import LayerSwitcher from "ol-layerswitcher";
import TileLayer from "ol/layer/Tile";
import { details_on_click } from "./customerdetails";
import { click_point_indirect_source, click_point_rural_source } from "./bills";
import { geoserver_url } from "../config";

var url_string = window.location;
var url = new URL(url_string);
var dist_id = url.searchParams.get("dist_id");
var feeder_id = url.searchParams.get("feed_id");
var transformer_id = url.searchParams.get("trans_id");
var bym = url.searchParams.get("bym");
// console.log(typeof transformer_id);

let satellite_layer_flag = false;

var geoserver_workspace = "new_gis_testing_workspace";

var direct_layer_name_in_geoserver = "matched_db";
var indirect_layer_name_in_geoserver = "indirect_consumer";
var rural_layer_name_in_geoserver = "rural_consumers";
var dss_layer_name_in_geoserver = "dss";
var substation_layer_name_in_geoserver = "injection_substation";
var htpole_layer_name_in_geoserver = "ht_pole";
var ltpole_layer_name_in_geoserver = "lt_pole";
var feeder_layer_name_in_geoserver = "feeder_line";
var lt_pole_connecting_lines_name_geoserver = "lt_pole_connecting_lines";
var d2i_layer_name_in_geoserver = "direct_to_indirect_connection";
var d2r_layer_name_in_geoserver = "direct_to_rural_connection";

const Tool_Box_Legend = document.getElementById("Tool_box_legend");

var Subsation_layer_button = document.getElementById("INJECTION S/S");
var Feeder_layer_button = document.getElementById("FEEDER");
var Ht_pole_layer_button = document.getElementById("HT POLE");
var Dss_layer_button = document.getElementById("DSS");
var Lt_pole_layer_button = document.getElementById("LT POLE");
var Lt_pole_connection_line_layer_button =
  document.getElementById("LT POLE LINE");
var Direct_consumer_layer_button = document.getElementById("DIRECT CONSUMER");
var Rural_consumer_layer_button = document.getElementById("RURAL CONSUMER");
var Indirect_consumer_layer_button =
  document.getElementById("INDIRECT CONSUMER");
var D2I_layer_button = document.getElementById("D2I");
var D2R_layer_button = document.getElementById("D2R");

const dss_img = document.getElementById("legend");
const direct_img = document.getElementById("direct-legend");

// var get_feeder_by_url_for_all_in_one_layer = feeder_id
//   ? " and t_feeder_id=" + feeder_id
//   : "";
// var get_transformer_by_url_for_all_in_one_layer = transformer_id
//   ? " and t_transformer_id=" + transformer_id
//   : "";

// function t_district_id(id) {
//   let dist = "";
//   if (id == null) {
//     $.notify("Please Give District ID", {
//       color: "#fff",
//       background: "#D44950",
//       delay: 3000,
//     });
//   } else if (id == "1") {
//     dist = "t_district_id=1";
//   } else if (id == "2") {
//     dist = "t_district_id=2";
//   } else if (id == "3") {
//     dist = "t_district_id=3";
//   } else if (id == "4") {
//     dist = "t_district_id=4";
//   } else if (id == "5") {
//     dist = "t_district_id=5";
//   } else if (id == "6") {
//     dist = "t_district_id=6";
//   } else if (id == "7") {
//     dist = "t_district_id=7";
//   } else if (id == "8") {
//     dist = "t_district_id=8";
//   } else if (id == "9") {
//     dist = "t_district_id=9";
//   } else if (id == "10") {
//     dist = "t_district_id=10";
//   } else if (id == "11") {
//     dist = "t_district_id=11";
//   } else if (id == "12") {
//     dist = "t_district_id=12";
//   } else if (id == "13") {
//     dist = "t_district_id=13";
//   } else if (id == "14") {
//     dist = "t_district_id=14";
//   } else if (id == "15") {
//     dist = "t_district_id=15";
//   } else if (id == "16") {
//     dist = "t_district_id=16";
//   } else if (id == "17") {
//     dist = "t_district_id=17";
//   } else if (id == "18") {
//     dist = "t_district_id=18";
//   } else {
//     dist = "t_district_id=" + id;
//   }
//   return dist;
// }

let Dft_filters = [];

if (dist_id) {
  Dft_filters.push("t_district_id=" + dist_id);
}

if (feeder_id) {
  Dft_filters.push("t_feeder_id=" + feeder_id);
}

if (transformer_id) {
  Dft_filters.push("t_transformer_id=" + transformer_id);
}

let DFT_CQL_FILTER = Dft_filters.join(" and ");

// console.log(DFT_CQL_FILTER);

let DF_filters = [];

if (dist_id) {
  DF_filters.push("t_district_id=" + dist_id);
}

if (feeder_id) {
  DF_filters.push("t_feeder_id=" + feeder_id);
}

let DF_CQL_FILTER = DF_filters.join(" and ");
// console.log(DF_CQL_FILTER);

// console.log(DF_CQL_FILTER);

const osm = new TileLayer({
  type: "base",
  baseLayer: true,
  visible: true,
  displayInLayerSwitcher: true,
  source: new OSM(),
});

const topo = new TileLayer({
  type: "base",
  baseLayer: true,
  visible: false,
  displayInLayerSwitcher: true,
  source: new XYZ({
    url: "https://tile.opentopomap.org/{z}/{x}/{y}.png",
  }),
});

const Satellite = new TileLayer({
  id: "satellite-1",
  type: "base",
  baseLayer: true,
  visible: false,
  displayInLayerSwitcher: true,
  maxZoom: 18,
  source: new XYZ({
    attributions: [
      "Powered by Esri",
      "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
    ],
    attributionsCollapsible: false,
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    maxZoom: 23,
  }),
});

var dss_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + dss_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var ht_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + htpole_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var substation_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + substation_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var feeder_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + feeder_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var direct_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + direct_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var indirect_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + indirect_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var ltpole_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + ltpole_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var rura_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + rural_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var lt_pole_view_sourec = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + lt_pole_connecting_lines_name_geoserver,
    // TILED: "true",
  },
});

var lt_pole_view = new TileLayer({
  id: "lt_pole_line-1",
  type: "lt pole",
  title: "LT POLE LINE",
  visible: false,
  displayInLayerSwitcher: false,
  source: lt_pole_view_sourec,
});

var dss = new TileLayer({
  id: "dss-1",
  title: "DSS",
  visible: false,
  displayInLayerSwitcher: false,
  source: dss_source,
});

var ht = new TileLayer({
  id: "ht_pole-1",
  title: "HT POLE",
  visible: false,
  displayInLayerSwitcher: false,
  source: ht_source,
});

var substation = new TileLayer({
  id: "substation-1",
  title: "INJECTION S/S",
  visible: false,
  displayInLayerSwitcher: false,
  source: substation_source,
});

var feeder = new TileLayer({
  id: "feeder-1",
  title: "FEEDER",
  visible: false,
  displayInLayerSwitcher: false,
  source: feeder_source,
});

var direct_consumer_layer = new TileLayer({
  id: "direct-1",
  title: "DIRECT CONSUMER",
  visible: true,
  displayInLayerSwitcher: false,
  source: direct_source,
});

var rural_consumer_layer = new TileLayer({
  id: "rural-1",
  title: "RURAL CONSUMER",
  visible: false,
  displayInLayerSwitcher: false,
  source: rura_source,
});

var indirect_consumer_layer = new TileLayer({
  id: "indirect-1",
  title: "INDIRECT CONSUMER",
  visible: false,
  displayInLayerSwitcher: false,
  source: indirect_source,
});

var ltpole = new TileLayer({
  id: "lt_pole-1",
  title: "LT POLE",
  visible: false,
  displayInLayerSwitcher: false,
  source: ltpole_source,
});

var d2i_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + d2i_layer_name_in_geoserver,
    // TILED: "true",
  },
});

var d2i = new TileLayer({
  id: "d2i-1",
  title: "D2I",
  visible: false,
  displayInLayerSwitcher: false,
  source: d2i_source,
});

// var cql_filter =
//   t_district_id(dist_id) +
//   get_feeder_by_url_for_all_in_one_layer +
//   get_transformer_by_url_for_all_in_one_layer;

let Direct_Cql_Filter =
  DFT_CQL_FILTER == "" ? "" : "&cql_filter=" + DFT_CQL_FILTER;

const url3 =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  ":" +
  direct_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Direct_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

fetch(url3)
  .then((response) => response.text())
  .then((data) => {
    var format = new GeoJSON();
    var feature = format.readFeatures(data, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });
    if (feature.length > 0) {
      Tool_Box_Legend.style.border = "0.5px solid #0e918c";
      Tool_Box_Legend.style.backgroundColor = "#0e918c";
      const direct_updateLegend = function (resolution) {
        var direct_visible = direct_consumer_layer.getVisible();
        if (direct_visible) {
          const graphicUrl = direct_source.getLegendUrl(resolution);
          const img = document.getElementById("direct-legend");
          img.src = graphicUrl;
        } else {
          if (!dss.getVisible()) {
            Tool_Box_Legend.style.border = "0.5px solid white";
            Tool_Box_Legend.style.backgroundColor = "white";
          }
          const img = document.getElementById("direct-legend");
          img.src = "";
        }
      };

      // Initial legend
      const resolution1 = map.getView().getResolution();
      direct_updateLegend(resolution1);

      // Update the legend when the resolution changes
      map.getView().on("change:resolution", function (event) {
        const resolution1 = event.target.getResolution();
        direct_updateLegend(resolution1);
      });
    } else {
      $.notify("No Data Found In Direct Consumer", {
        color: "#fff",
        background: "#D44950",
        delay: 3000,
      });
      Tool_Box_Legend.disabled = true;
      Tool_Box_Legend.style.border = "0.5px solid white";
      Tool_Box_Legend.style.backgroundColor = "white";
    }
  });

const parser = new WMSCapabilities();

let directextend = [];
let indirectextend = [];
let ltpoleextend = [];
let dssextend = [];
let feederextend = [];
let htpoleextend = [];
let substationextend = [];
let ltpolelineviewextend = [];
let ruralextend = [];
let d2iextend = [];
let d2rextend = [];

var url =
  geoserver_url +
  "geoserver/wms?request=GetCapabilities&service=WMS&version=1.1.1";

$.ajax(url).done(function (response) {
  var result = parser.read(response);
  var Layers = result.Capability.Layer.Layer;
  var extent;
  for (var i = 0, len = Layers.length; i < len; i++) {
    var layerobj = Layers[i];

    if (
      layerobj.Name ==
      geoserver_workspace + ":" + direct_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      directextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + dss_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      dssextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + feeder_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      feederextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + htpole_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      htpoleextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + substation_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      substationextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + rural_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      ruralextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + d2i_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      d2iextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + lt_pole_connecting_lines_name_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      ltpolelineviewextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + indirect_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      indirectextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + ltpole_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      ltpoleextend = extent_38571;
    }
    if (
      layerobj.Name ==
      geoserver_workspace + ":" + d2r_layer_name_in_geoserver
    ) {
      extent = layerobj.BoundingBox[0].extent;
      var extent_38571 = transformExtent(extent, "EPSG:4326", "EPSG:3857");
      d2rextend = extent_38571;
    }
  }
});

if (DFT_CQL_FILTER !== "") {
  dss.getSource().updateParams({ CQL_FILTER: DFT_CQL_FILTER });
  direct_consumer_layer
    .getSource()
    .updateParams({ CQL_FILTER: DFT_CQL_FILTER });
  indirect_consumer_layer
    .getSource()
    .updateParams({ CQL_FILTER: DFT_CQL_FILTER });
  ltpole.getSource().updateParams({ CQL_FILTER: DFT_CQL_FILTER });
  rural_consumer_layer.getSource().updateParams({ CQL_FILTER: DFT_CQL_FILTER });
  lt_pole_view.getSource().updateParams({ CQL_FILTER: DFT_CQL_FILTER });
  d2i.getSource().updateParams({ CQL_FILTER: DFT_CQL_FILTER });
}

if (DF_CQL_FILTER !== "") {
  ht.getSource().updateParams({ CQL_FILTER: DF_CQL_FILTER });
  substation.getSource().updateParams({ CQL_FILTER: DF_CQL_FILTER });
  feeder.getSource().updateParams({ CQL_FILTER: DF_CQL_FILTER });
}

map.addLayer(osm);
map.addLayer(topo);
map.addLayer(Satellite);
map.addLayer(substation);
map.addLayer(feeder);
map.addLayer(ht);
map.addLayer(dss);
map.addLayer(ltpole);
map.addLayer(lt_pole_view);
map.addLayer(direct_consumer_layer);
map.addLayer(rural_consumer_layer);
map.addLayer(indirect_consumer_layer);
map.addLayer(d2i);

var D2R_source = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + d2r_layer_name_in_geoserver,
    // CQL_FILTER:
    //   t_district_id(dist_id) +
    //   get_feeder_by_url_for_all_in_one_layer +
    //   get_transformer_by_url_for_all_in_one_layer,
    // TILED: "true",
  },
});

var D2R = new TileLayer({
  id: "d2r-1",
  title: "D2R",
  visible: false,
  displayInLayerSwitcher: false,
  source: D2R_source,
});

map.addLayer(D2R);

if (DFT_CQL_FILTER !== "") {
  D2R.getSource().updateParams({ CQL_FILTER: DFT_CQL_FILTER });
}

var fullscreenControl = new FullScreen({
  className: "my-fullscreen1",
  source: document.getElementById("map").parentNode,
});

map.addControl(fullscreenControl);

var direct_source1 = new TileWMS({
  url: geoserver_url + "geoserver/" + geoserver_workspace + "/wms",
  params: {
    LAYERS: geoserver_workspace + ":" + direct_layer_name_in_geoserver,
    cql_filter: "",
    // TILED: "true",
  },
});

var searched_consumer_layer = new TileLayer({
  id: "Searched_consumer_1",
  title: "SEARCHED CONSUMER",
  displayInLayerSwitcher: false,
  visible: false,
  source: direct_source1,
});

const Details_on_Click_function = function (evt) {
  details_on_click(evt, searched_consumer_layer.getSource());
};

document
  .getElementById("Tool_box_search")
  .addEventListener("click", function (evt) {
    var input1 = document.getElementById("Tool_box_input").value;
    if (input1 != "") {
      var cql_filter = "consumer_no=" + input1;
      searched_consumer_layer
        .getSource()
        .updateParams({ CQL_FILTER: cql_filter });

      map.getLayers().forEach(function (layer) {
        if (layer.get("title") == "SEARCHED CONSUMER") map.removeLayer(layer);
      });

      direct_consumer_layer.setVisible(false);
      indirect_consumer_layer.setVisible(false);
      rural_consumer_layer.setVisible(false);
      substation.setVisible(false);
      feeder.setVisible(false);
      ht.setVisible(false);
      dss.setVisible(false);
      ltpole.setVisible(false);
      lt_pole_view.setVisible(false);
      d2i.setVisible(false);

      Direct_consumer_layer_button.checked = false;
      Indirect_consumer_layer_button.checked = false;
      Rural_consumer_layer_button.checked = false;
      Subsation_layer_button.checked = false;
      Feeder_layer_button.checked = false;
      Ht_pole_layer_button.checked = false;
      Dss_layer_button.checked = false;
      Lt_pole_layer_button.checked = false;
      Lt_pole_connection_line_layer_button.checked = false;
      D2I_layer_button.checked = false;
      D2R_layer_button.checked = false;

      dss_img.src = "";
      direct_img.src = "";

      map.addLayer(searched_consumer_layer);
      searched_consumer_layer.setVisible(true);
      document.querySelector(".search_consumer").style.display = "block";
      document.getElementById("SEARCH CONSUMER").checked = true;
    } else {
      // alert("please enter consumer no");
    }
  });

map.on("click", function (evt) {
  map.getLayers().forEach(function (layer) {
    if (layer.get("title") == "SEARCHED CONSUMER")
      if (layer.getVisible() == true) {
        map.on("click", Details_on_Click_function);
      } else if (layer.getVisible() == false) {
        map.un("click", Details_on_Click_function);
      }
  });
});

$(document).on("click", "#direct_extend", function () {
  if (direct_consumer_layer.getVisible()) {
    map.getView().fit(directextend, map.getSize());
  }
});

$(document).on("click", "#dss_extend", function () {
  if (dss.getVisible()) {
    map.getView().fit(dssextend, map.getSize());
  }
});

$(document).on("click", "#ht_pole_extend", function () {
  if (ht.getVisible()) {
    map.getView().fit(htpoleextend, map.getSize());
  }
});

$(document).on("click", "#substation_extend", function () {
  if (substation.getVisible()) {
    map.getView().fit(substationextend, map.getSize());
  }
});

$(document).on("click", "#feeder_extend", function () {
  if (feeder.getVisible()) {
    map.getView().fit(feederextend, map.getSize());
  }
});

$(document).on("click", "#rural_extend", function () {
  if (rural_consumer_layer.getVisible()) {
    map.getView().fit(ruralextend, map.getSize());
  }
});

$(document).on("click", "#indirect_extend", function () {
  if (indirect_consumer_layer.getVisible()) {
    map.getView().fit(indirectextend, map.getSize());
  }
});

$(document).on("click", "#lt_pole_extend", function () {
  if (ltpole.getVisible()) {
    map.getView().fit(ltpoleextend, map.getSize());
  }
});

$(document).on("click", "#lt_pole_line_extend", function () {
  if (lt_pole_view.getVisible()) {
    map.getView().fit(ltpolelineviewextend, map.getSize());
  }
});

$(document).on("click", "#d2i_extend", function () {
  if (d2i.getVisible()) {
    map.getView().fit(d2iextend, map.getSize());
  }
});

$(document).on("click", "#d2r_extend", function () {
  if (D2R.getVisible()) {
    map.getView().fit(d2rextend, map.getSize());
  }
});

var summary_flag = false;
var summary_Count_button = document.getElementById("summary");
var summary_closer_button = document.getElementById("close2");

summary_Count_button.addEventListener("click", function () {
  summary_flag = true;
});

summary_closer_button.addEventListener("click", function () {
  summary_flag = false;
});

map.on("click", function (evt) {
  if (summary_flag) {
    $("#close2").click();
  }
});

map.on("loadstart", function () {
  map.getTargetElement().classList.add("spinner");
});
map.on("loadend", function () {
  map.getTargetElement().classList.remove("spinner");
});

export {
  geo_consumer_no,
  direct_visible,
  satellite_layer_flag,
  // t_district_id,
  get_feeder_by_url_for_all_in_one_layer,
  get_transformer_by_url_for_all_in_one_layer,
  dist_id,
  feeder_id,
  bym,
  transformer_id,
  direct_source,
  dss_source,
  osm,
  topo,
  Satellite,
  substation,
  feeder,
  ht,
  dss,
  ltpole,
  lt_pole_view,
  direct_consumer_layer,
  rural_consumer_layer,
  indirect_consumer_layer,
  d2i,
  D2R,
  searched_consumer_layer,
  DFT_CQL_FILTER,
  DF_CQL_FILTER,
};
