// var user_button = document.getElementById("search_consumer");
// user_button.addEventListener("click", function () {
//   document.getElementById("cont").style.display = "block";
// });

// var close_button = document.getElementById("submit");
// close_button.addEventListener("click", function () {
//   document.getElementById("cont").style.display = "none";
// });

var summary_button = document.getElementById("summary");
summary_button.addEventListener("click", function () {
  document.getElementById("summary_div").style.display = "block";
});

var close_button2 = document.getElementById("close2");
close_button2.addEventListener("click", function () {
  document.getElementById("summary_div").style.display = "none";
});

const bill_details_parent = $("#nav-hidden");
function goBack() {
  document.getElementById("nav-billspayment-tab").click();
  document.getElementById("list-div").innerHTML = "";
}

async function copyText(value) {
  // console.log({ value });
  const cpText = await navigator.clipboard.writeText(value);
  // Copy the text inside the text field
  return cpText;
}

$(document).on("click", "#myInput", async function () {
  copyText($(this).html());
});

function openForm() {
  document.getElementById("popup").style.display = "block";
}

function closeForm() {
  document.getElementById("popup").style.display = "none";
}

var Go_back_button = document.getElementById("btn-back");

Go_back_button.addEventListener("click", goBack);
