import map from "./map";
import Overlay from "ol/Overlay";
// import detailpop from "./detailsoverlay";
import { click } from "./customerdetails";

const container = document.getElementById("popup1");
const content = document.getElementById("popup-content1");
const closer = document.getElementById("popup-closer1");

const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};


let selected = null;
function cardpop(evt) {
  const coordinate = evt.coordinate;
  if (selected !== null) {
    selected = null;
    overlay.setPosition(undefined);
    content.innerHTML = ``;
  }
  map.forEachFeatureAtPixel(evt.pixel, function (f) {
    selected = f;
    return true;
  });
  content.innerHTML = `
  <div class="container">

  <div class="card" style=" background: #ADD8E6; position: absolute;  width: 370px;
  right: 0;">
      <div class="row">
          <div class="col-3">
              <img src=${selected.get("avatar")}   alt="Avatar" class="avatar">
          </div>
          <div class="col-6">
              <h5 class="card-title1">${selected.get("name")} </h5>
              <p class="subtitle">Punch-Out @ ${selected.get(
                "activeStatus"
              )}</p>
              <p class="subtitle"><i class="fa-solid fa-location-dot"></i> Mumbai @ ${selected.get(
                "lastActiveTime"
              )} </p>
                 


              
              <!-- <h6 class="card-subtitle mb-2 text-muted">Punch_Out @ 7:20pm</h6>
      <h6 class="card-subtitle mb-2 text-muted">Mumbai @ 7:20pm</h6> -->
          </div>

          <div class="col-3" style="margin-top :22px;">
              
              <div class="item">
                  <span><i class="fa-solid fa-mobile-screen-button fa-2xl " id="mobile"></i></span>
                  <!-- <span><i class="fa-solid fa-mobile-screen-button fa-2xl fa-fade"></i></span> -->
                  <span class="caption">
                      <i class="fa-solid fa-battery-full" id="battery"></i>
                      <!-- <i class="fa-solid fa-battery-empty fa-fade"></i> -->
                      <!-- <i class="fa-solid fa-location-dot"></i> -->
                      <i class="fa-solid fa-location-dot fa-fade" id="gps"></i>
                      <!-- <i class="fa-solid fa-signal "></i> -->
                      <i class="fa-solid fa-signal fa-fade" id="network"></i>
                  </span>
              </div>
          </div>
      </div>
      <hr class="horizontal-line">
      <div class="row" style="margin-left: 0px; margin-right: 0px; margin-bottom: 0 ">
          <div class="col-3">
              <div class="circle" data-progress="70" style="--progress: 220deg;"></div>
          </div>
          <div class="col-9 d-flex justify-content-between vl">
              <div class="item">
                  <span><i class="fa-solid fa-id-card"></i></span>
                  <span class="caption1">${selected.get(
                    "visistsCount"
                  )}  </span>
              </div>
              <div class="item">
                  <span><i class="fa-solid fa-route"></i></span>
                  <span class="caption1">${selected.get(
                    "distaceCovered"
                  )}</span>
              </div>
              <div class="item">
                  <span><i class="fa-solid fa-laptop-code"></i></span>
                  <span class="caption1">${selected.get("lastLocation")}</span>
              </div>
          </div>

      </div>
      <!-- <hr class="horizontal-line"> -->
      <hr class="horizontal-line">

      <div class="timeline d-flex justify-content-between">
          <!-- <h6>TIMELINE</h6>
         
                  <input type="date" id="select-date" name="select-date" >
      </div> -->
          <div class="container" style="margin: 0;">
              <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button class="nav-link active" id="nav-timeline-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-timeline" type="button" role="tab" aria-controls="nav-timeline"
                          aria-selected="true">Timeline</button>
                      <button class="nav-link" id="nav-chat-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-chat" type="button" role="tab" aria-controls="nav-chat"
                          aria-selected="false">Chat</button>

                  </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-timeline" role="tabpanel"
                      aria-labelledby="nav-timeline-tab">

                      <div class="container timeline-tab" id="style-1">
                          <div class="message-box">
                              <textarea type="text" class="message-input" placeholder="Search..."></textarea>
                              <button class="message-submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                          </div>
                          
                          <div class="force-overflow"id="element1">
                          </div>
                     
                          <div>
                              <a href="javascript:void(0);" id="add">Add More</a>
                          </div>
                      </div>

                  </div>
                  <div class="tab-pane fade" id="nav-chat" role="tabpanel"
                      aria-labelledby="nav-chat-tab">
                      <div class="container">
                      <div class="chat">
                      <div class="chat-title">
                        <h1>Fabio Ottaviani</h1>
                        <h2>Supah</h2>
                        <figure class="avatar">
                          <img
                            src=${selected.get("avatar")}
                          />
                        </figure>
                      </div>
                      <div class="messages" id="chat">
                        <div class="messages-content"></div>
                      </div>
                      <div class="message-box">
                        <textarea
                          type="text"
                          id="newmsg"
                          class="message-input"
                          placeholder="Type message..."
                        ></textarea>
                        <button type="submit" id="send" class="message-submit">Send</button>
                      </div>
                    </div>
                    <div class="bg"></div>
                      </div>
                  </div>
              </div>
          </div>
          <input type="date" value="2022-10-10">
      </div>

  </div>
</div>
</div>`;
  overlay.setPosition(coordinate);

  $("#mobile").ready(function () {
    const btn = document.getElementById("mobile");
    var vars = `${selected.get("isActive")}`;
    // console.log(vars);
    if (`${selected.get("isActive")}` == "true") {
      btn.style.color = "green";
    } else {
      btn.style.color = "red";
    }
  });

  $("#battery").ready(function () {
    const btn = document.getElementById("battery");
    var vars = `${selected.get("batteryStatus")}`;
    // console.log(vars);
    if (`${selected.get("batteryStatus")}` <= "18") {
      btn.style.color = "red";
    } else {
      btn.style.color = "green";
    }
  });

  $("#gps").ready(function () {
    const btn = document.getElementById("gps");
    var vars = `${selected.get("gpsStatus")}`;
    // console.log(vars);
    if (`${selected.get("gpsStatus")}` == "false") {
      btn.style.color = "red";
    } else {
      btn.style.color = "green";
    }
  });

  $("#network").ready(function () {
    const btn = document.getElementById("network");
    var vars = `${selected.get("networkStatus")}`;
    // console.log(vars);
    if (`${selected.get("networkStatus")}` == "false") {
      btn.style.color = "red";
    } else {
      btn.style.color = "green";
    }
  });

  $(document).ready(function () {
    $(function () {
      $("#element1").timeline({
        data: [
          {
            time: new Date(),
            color: "#555",
            css: "success",
            content: [
              {
                date: "",
                header: `${selected.get("consumerName")}`,
                body: `${selected.get("actionTaken")}`,
                // body: `${selected.get("actionDate")}`,
                footer: `${selected.get("description")}`,
              },
            ],
          },
          {
            time: new Date(),
            color: "#00ff00",
            css: "success",
            content: [
              {
                date: "",
                header: `${selected.get("consumerName")}`,
                body: `${selected.get("actionDate")}`,
                // body: `${selected.get("actionDate")}`,
                footer: `${selected.get("description")}`,
              },
            ],
          },
          {
            time: new Date(),
            color: "#000",
            css: "success",
            content: [
              {
                date: "",
                header: `${selected.get("consumerName")}`,
                body: `${selected.get("actionStatus")}`,
                // body: `${selected.get("actionDate")}`,
                footer: `${selected.get("description")}`,
              },
            ],
          },
        ],
      });
      $("#add").click(function () {
        $("#element1").timeline("add", [
          {
            time: new Date(),
            css: "success",
            content: [
              {
                // date: "",
                header: `${selected.get("consumerName")}`,
                body: `${selected.get("actionStatus")}`,
                // body: `${selected.get("actionDate")}`,
                footer: `${selected.get("description")}`,
              },
            ],
          },
        ]);
      });
    });
  });

  $(document).ready(function () {
    $("#datepicker").datepicker();
    $(".fa-calendar").click(function () {
      $("#datepicker").focus();
    });
  });

  var text = document.querySelector("#newmsg");
  var send = document.querySelector("#send");
  var chatbox = document.querySelector("#chat");

  var Fake = [
    "Hi there, I'm ChatBot and you?",
    "Nice to meet you",
    "How are you?",
    "Not too bad, thanks",
    "What do you do?",
    "That's awesome",
    "Codepen is a nice place to stay",
    "I think you're a nice person",
    "Why do you think that?",
    "Can you explain?",
    "Anyway I've gotta go now",
    "It was a pleasure chat with you",
    "Time to make a new codepen",
    "Bye",
    ":)",
  ];

  var i = 0;

  send.addEventListener("click", function () {
    var d = new Date();
    var n = d.toLocaleTimeString();

    var msg = text.value;

    if (msg) {
      chatbox.innerHTML +=
        "<section class='send message'><section class='box'>" +
        msg +
        "</section><section class='time'>" +
        n +
        "</section></section>";
      text.value = "";

      setTimeout(function () {
        var d = new Date();
        var n = d.toLocaleTimeString();

        chatbox.innerHTML +=
          "<section class='reci message'><section class='box'>" +
          Fake[i] +
          "</section><section class='time'>" +
          n +
          "</section></section>";
        text.value = "";
        i++;
      }, Math.ceil(Math.random() * 2000) + 1000);
    }
  });
}

var tool = null;
var isMessageToolEnable = false;
$("#button").click(function () {
  const btn = document.getElementById("button");
  btn.style.backgroundColor = "#3883fc";
  if (!isMessageToolEnable) {
    // map.un("click", click);
    map.on("click", cardpop);
    // console.log("clicked");
    isMessageToolEnable = true;
  } else {
    btn.style.backgroundColor = "lightsteelblue";
    map.un("click", cardpop);
    // console.log("unclicked");
    isMessageToolEnable = false;
  }
});

map.addOverlay(overlay);

// console.log("1111");
// var feature = map.forEachFeatureAtPixel(evt.pixel, function (layer) {
// if (sidebar_global == true) {
//   var sidebar_button = document.getElementById("profile_tab");
//   $(sidebar_button).click();
//   sidebar_global = false;
//   // console.log("true clicked");
// } else {
// }
// bpDetailsParentDiv.empty();
// bpParentDiv.empty();
// indirect_parent_div.empty();
// const viewResolution = /** @type {number} */ (map.getView().getResolution());
// const url = direct_source.getFeatureInfoUrl(
//   evt.coordinate,
//   viewResolution,
//   "EPSG:3857",
//   { INFO_FORMAT: "application/json" }
// );
// $.ajax({
//   type: "GET",
//   dataType: "json",
//   url: url,
// }).done(function (data) {
//   // console.log(data);
//   var no = data.features[0].properties.consumer_no;
//   var coord = data.features;
//   consumer_details(no);
//   highlight_source.clear();
//   var features = new GeoJSON().readFeatures(data);
//   highlight_source.addFeatures(features);
//   if (data.features[0].properties.connection_type == "Direct") {
//     if (global == false) {
//       var myCollapseEl = document.getElementById("leftArrow");
//       $(myCollapseEl).click();
//       var details_tab = document.getElementById("nav-details-tab");
//       $(details_tab).click();
//     }
//   } else {
//   }
// });
// var scroll_div = document.getElementById("parent-payment-div");
// .scrollTop = 0);
// console.log(scroll_div);
// scroll_div.scrollTop = (0, 0);
// const bpDetailsParentDiv = $("#nav-hidden");

// url:
//   baseurl +
//   `/api/v1/historical/bill-payment?consumer_no=3875379008&bill_year_month=` +
//   // `/api/v1/historical/bill-payment?consumer_no=${consumernumber}&bill_year_month=` +
//   date,

export { overlay, cardpop };
