import { geoserver_url } from "../config";

const bpDetailsParentDiv = $("#list-div");
const indirect_parent_div = $("#indirect");

function building_use(type) {
  let bduse = "";
  if (type == "Commercial") {
    bduse = "C";
  } else if (type == "Residential") {
    bduse = "R";
  } else if (type == "Domestic") {
    bduse = "D";
  } else if (type == "Industrial") {
    bduse = "I";
  } else if (type == "Special") {
    bduse = "S";
  } else {
    bduse = "";
  }
  return bduse;
}

function consumerBuildType(type) {
  let bdType = "";
  if (type == "Block of Rooms") {
    bdType = "BR";
  } else if (type == "Block of Flats") {
    bdType = "BF";
  } else if (type == "Block of Shop") {
    bdType = "BS";
  } else if (type == "Block of Shops") {
    bdType = "BS";
  } else if (type == "Duplex") {
    bdType = "DP";
  } else if (type == "Bungalow") {
    bdType = "BG";
  } else if (type == "Church") {
    bdType = "CH";
  } else if (type == "Face to Face") {
    bdType = "FF";
  } else if (type == "Units of rooms") {
    bdType = "UR";
  } else if (type == "Others") {
    bdType = "OT";
  } else if (type == "Mudhouse") {
    bdType = "MH";
  } else if (type == "Store") {
    bdType = "S";
  } else if (type == "Shack") {
    bdType = "SH";
  } else if (type == "Office Complex") {
    bdType = "OC";
  } else if (type == "Filling Station") {
    bdType = "FS";
  } else if (type == "Cointainer") {
    bdType = "C";
  } else {
    bdType = "";
  }
  return bdType;
}

function receipt_counter(receipts) {
  let receipt_array = [];

  var receipt_single = Object.values(receipts);
  receipt_single.forEach((rec) => {
    // console.log(rec,"for each");
    rec.forEach((rec_s) => {
      // console.log(rec_s,"foreach");
      receipt_array.push(rec_s);
      // console.log(receipt_array.length);
    });
  });

  var receipt_count1 = receipt_array.length;
  var receipt_count = `<span class="badge text-bg-primary" id="receipt_count" style="
    font-size: xx-small;
    ">${receipt_count1}</span>`;

  if (receipt_count1 != 0) {
    // console.log(receipt_count1);
    $("#receipt_count").remove();
    $("#nav-billspayment-tab").append(receipt_count);
  }
}

function indirect_counter(indirect_response_count) {
  var indirect_count = `<span class="badge text-bg-primary" id="indirect_count" style="
  font-size: xx-small;
  ">${indirect_response_count}</span>`;
  $("#indirect_count").remove();
  $("#nav-indirect-tab").append(indirect_count);
}

function rural_counter(rural_response_count) {
  var rural_count = `<span class="badge text-bg-secondary" id="rural_count" style="
    font-size: xx-small;
  ">${rural_response_count}</span>`;
  $("#rural_count").remove();
  $("#nav-indirect-tab").append(rural_count);
}

function showBillsDetails(bills) {
  var bill_sum = `<div class="card" id="bills">
    <div class="container" id="second1">
        <div class="d-flex justify-content-between">
            <div class="text-muted heading">
                Amount
            </div>
            <div class="text-muted heading">
                Usage
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between">
                <div class="text-muted mt-2">&#8358</div>
                <div class="fw-bolder fs-4">&nbsp; ${bills.bill_amount}</div>
            </div>
            <div class="fw-bolder fs-4">
                ${bills.units}<i class="fa-solid fa-bolt"></i>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="text-muted heading">
                Month Due:
            </div>
            <div class="text-muted heading">
                Current Reading:
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between">
                <div class="text-muted ">&#8358</div>
                <div class="fw-bolder fs-6">&nbsp;${bills.total_due}</div>
            </div>
            <div class="fw-bolder fs-6">
                -
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="text-muted heading">
                Arrears :
            </div>
            <div class="text-muted heading">
                Last Reading:
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-between">
                <div class="text-muted ">&#8358</div>
                <div class="fw-bolder fs-6">&nbsp;${bills.arrears}</div>
            </div>
            <div class="fw-bolder fs-6">
                -
            </div>
        </div>
        <div class="fw-lighter text-muted bill-no">
            Bill No: ${bills.bill_no}
        </div>
    </div>
  </div>
  </div>`;
  bpDetailsParentDiv.append(bill_sum);
}

function showReceiptsDetails(receipts) {
  receipts.forEach((rr) => {
    var receipt_sum = `<div class="card border-left-primary1 shadow mt-1 px-3 py-1" id="receipt">
      <div class="d-flex justify-content-between">
          <div class="text-muted  heading">
              Amount
          </div>
          <div>Receipt </div>
      </div>
      <div class="d-flex">
          <div class="text-muted mt-2">&#8358</div>
          <div class="fw-bolder fs-4">&nbsp;${rr.amount}</div>
          <div class="mt-1">
              &nbsp;<i class="fa-solid fa-circle-check fa-lg"></i>
          </div>
      </div>
      <div class="text-muted heading">
          Purpose:
      </div>
      <div class="fw-bold fs-6">
          bill
      </div>
      <div class="payment-details">
          Paid at ${rr.latest_payment_date}
      </div>
      <div class="payment-details text-danger">
          Mode of payment: buypower
      </div>
      <div class="payment-details">
          Receipt No: -
      </div>
  </div>`;
    bpDetailsParentDiv.append(receipt_sum);
  });
}

function showVendingsDetails(vendings) {
  vendings.forEach((ven) => {
    var vending_sum = `<div class="card border-left-primary2 shadow mt-1 px-3 py-1" id="vending">
      <div class="d-flex justify-content-between">
          <div class="text-muted  heading">
              Amount
          </div>
          <div>Vending</div>
      </div>
      <div class="d-flex">
          <div class="text-muted mt-2">&#8358</div>
          <div class="fw-bolder fs-4">&nbsp;${ven.amount}</div>
          <div class="mt-1">
              &nbsp;<i class="fa-solid fa-circle-check fa-lg"></i>
          </div>
      </div>
      <div class="text-muted heading">
          Arrears:${ven.arrear_paid}
      </div>
      <div class="fw-bold fs-6">
          Paid:${ven.current_bill_paid}
      </div>
      <div class="payment-details">
          Paid at: ${ven.latest_payment_date}
      </div>
      <div class="text-muted heading">
          Consumer No:${ven.consumer_no}
      </div>
      <div class="payment-details text-danger">
          Mode of payment: buypower
      </div>
      <div class="payment-details text-danger">
          Month And Year: ${ven.bill_year_month}
      </div>
  </div>`;
    bpDetailsParentDiv.append(vending_sum);
  });
}

function house_colour(data) {
  if (data.occupancy_status == "Occupied") {
    return "blue";
  } else {
    return "grey";
  }
}

function indirectcard(indirect_data) {
  indirect_data.forEach((ind) => {
    var indirect = `<div class="card shadow px-1 py-1 outer-card mt-2" id="indirect-card1">
      <div class="card custom-card">
          <div class="row">
              <div class="col-2" style="padding-left:8px; padding-right: 4px;">
                  <div class="d-flex justify-content-between align-items-center h-100">
                      <div class="red-filled-rectangle"></div>
  
                      <div class="h-100" style="width: min-content">
  
                          <i class="fa-solid fa-certificate mb-3"></i>
                          <p class="box" data-bs-toggle="tooltip" title="Building Type = ${
                            ind.building_type
                          }">
                          ${
                            ind.building_type == null
                              ? "-"
                              : `${consumerBuildType(ind.building_type)}`
                          }</p>
                          <p class="box" data-bs-toggle="tooltip" title="Building Use = ${
                            ind.building_use
                          }">
                          ${
                            ind.building_use == null
                              ? "-"
                              : `${building_use(ind.building_use)}`
                          }</p>
                      </div>
                  </div>
              </div>
  
              <div class="col-8" style="padding-left: 0;">
  
                  <h6 style="font-weight: bolder; margin-bottom: 0px">
                      ${
                        ind.customer_name == null ? "-" : `${ind.customer_name}`
                      }
                  </h6>
                  <div class="fw-bold card-no-text mt-1">${
                    ind.accountnumber == null ? "-" : `${ind.accountnumber}`
                  }</div>
                  <div class="fw-bold card-no-text mt-1">${
                    ind.customer_phone_no_1 == null
                      ? "-"
                      : `${ind.customer_phone_no_1}`
                  }</div>
                  <div class=" cursor_change fw-bold card-no-text mt-1" id="myInput"
                      data-bs-toggle="tooltip" title="Click to Copy">${
                        ind.consu_code == null ? "-" : `${ind.consu_code}`
                      }</div>
  
                  <div class="text-muted lh-1 address mt-1">
                      ${ind.address == null ? "-" : `${ind.address}`}
                  </div>
              </div>
              <div class="col-2" style="padding-left: 0;">
  
                  <div class="h-100">
                      <div class="d-flex justify-content-between">
                          <i class="fa-solid fa-house-user" id="house" style="color:${house_colour(
                            ind
                          )}"></i>
                          <i class="fa-solid fa-location-dot loc " data-bs-toggle="tooltip" data-bs-placement="top"
                              title="Click To Go To Location"></i>
                          <div class="indirect" style="display: none" data-json='${JSON.stringify(
                            ind.wkb_geometry.coordinates
                          )}'>
                          ${JSON.stringify(ind.wkb_geometry.coordinates)}</div>
                      </div>
                      <p class="box3 mt-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Number of Flats">${
                        ind.number_of_flats == null
                          ? "-"
                          : `${ind.number_of_flats}`
                      }
                      </p>
                      <p class="box3" style="width: 25px;" data-bs-toggle="tooltip" data-bs-placement="top"
                          title="Number of Households">${
                            ind.number_of_households == null
                              ? "-"
                              : `${ind.number_of_households}`
                          }</p>
                  </div>
              </div>
          </div>
      </div>
  </div>`;
    indirect_parent_div.append(indirect);
  });
}

function ruralcard(ruraldata) {
  ruraldata.forEach((ind) => {
    var rural = `<div class="card shadow px-1 py-1 outer-card1 mt-2" id="rural-card1">
      <div class="card custom-card">
          <div class="row">
              <div class="col-2" style="padding-left: 8px; padding-right: 4px;">
                  <div class="d-flex justify-content-between align-items-center h-100">
                      <div class="green-filled-rectangle"></div>
                      <div class="h-100" style="width: min-content">
                          <i class="fa-solid fa-certificate mb-3"></i>
                          <p class="box" data-bs-toggle="tooltip" title="Building Type = ${
                            ind.building_type
                          }">
                          ${
                            ind.building_type == null
                              ? "-"
                              : `${consumerBuildType(ind.building_type)}`
                          }</p>
                          <p class="box" data-bs-toggle="tooltip" title="Building Use = ${
                            ind.building_use
                          }">
                          ${
                            ind.building_use == null
                              ? "-"
                              : `${building_use(ind.building_use)}`
                          }</p>
                      </div>
                  </div>
              </div>
  
              <div class="col-8" style="padding-left: 0;">
  
                  <h6 style="font-weight: bolder; margin-bottom: 0px">
                      ${
                        ind.customer_name == null ? "-" : `${ind.customer_name}`
                      }
                  </h6>
                  <div class="fw-bold card-no-text mt-1">${
                    ind.accountnumber == null ? "-" : `${ind.accountnumber}`
                  }</div>
                  <div class="fw-bold card-no-text mt-1">${
                    ind.customer_phone_no_1 == null
                      ? "-"
                      : `${ind.customer_phone_no_1}`
                  }</div>
                  <div class=" cursor_change fw-bold card-no-text mt-1" id="myInput" 
                      data-bs-toggle="tooltip" title="Click to Copy">${
                        ind.consu_code == null ? "-" : `${ind.consu_code}`
                      }</div>
  
                  <div class="text-muted lh-1 address mt-1">
                      ${ind.address == null ? "-" : `${ind.address}`}
                  </div>
              </div>
              <div class="col-2" style="padding-left: 0;">
                  <div>
                      <div class="d-flex justify-content-between">
                          <i class="fa-solid fa-house-user" style="color:${house_colour(
                            ind
                          )}"></i>
                          <i class="fa-solid fa-location-dot loc2" data-bs-toggle="tooltip" data-bs-placement="top"
                              title="Click To Go To Location"></i>
                          <div class="rural" style="display: none" data-json='${JSON.stringify(
                            ind.wkb_geometry.coordinates
                          )}'>
                          ${JSON.stringify(ind.wkb_geometry.coordinates)}</div>
                      </div>
                  </div>
  
                  <p class="box3 mt-2" data-bs-toggle="tooltip" title="Number of Flats">${
                    ind.number_of_flats == null ? "-" : `${ind.number_of_flats}`
                  }
                  </p>
                  <p class="box3" style="
                    width: 25px;" data-bs-toggle="tooltip" title="Number of Households">${
                      ind.number_of_households == null
                        ? "-"
                        : `${ind.number_of_households}`
                    }</p>
  
              </div>
          </div>
      </div>
  </div>
  </div>`;
    indirect_parent_div.append(rural);
  });
}

function generateCustomerDetails(data) {
  let Names = data;
  const html = `<div class="container mt-2" id="test">

  ${
    Names.name
      ? `<div class="row">
      <p class="title text-muted">NAME</p>
      <p class="subtitle mb-1">${Names.name}
      </p>
      </div>`
      : ""
  }

  ${
    Names.consumer_no
      ? `<div class="row">
      <p class="title text-muted">CONSUMER NUMBER</p>
      <p class="subtitle mb-1">${Names.consumer_no}
      </p>
      </div>`
      : ""
  }

  ${
    Names.address && Names.address?.address1 !== "null"
      ? `  <div class="row">
      <p class="title text-muted">ADDRESS</p>
      <p class="subtitle mb-1">${Names.address.address1}
      </p>
      </div>`
      : ""
  }

  ${
    Names.consumer_type
      ? `<div class="row">
      <p class="title text-muted">CUSTOMER TYPE</p>
      <p class="subtitle mb-1">${Names.consumer_type}</p>
      </div>`
      : ""
  }

  ${
    Names.connection_type
      ? `<div class="row">
      <p class="title text-muted">CONNECTION TYPE</p>
      <p class="subtitle mb-1">${Names.connection_type}</p>
      </div>`
      : ""
  }

  ${
    Names.phone
      ? `<div class="row">
      <p class="title text-muted">PHONE NUMBER</p>
      <p class="subtitle mb-1">${Names.phone}</p>
      </div>`
      : ""
  } 

  

  ${
    Names.meter_type
      ? `<div class="row">
    <p class="title text-muted">METER TYPE</p>
    <p class="subtitle mb-1">${Names.meter_type}</p>
    </div>`
      : ""
  }
  
  ${
    Names.meter_no != null
      ? `<div class="row">
      <p class="title text-muted">METER NUMBER</p>
      <p class="subtitle mb-1">${Names.meter_no}</p>
      </div>`
      : ""
  }

  ${
    Names.building_type
      ? `<div class="row">
      <p class="title text-muted">BUILDING TYPE</p>
      <p class="subtitle mb-1">${Names.building_type}</p>
      </div>`
      : ""
  }

  ${
    Names.status
      ? `<div class="row">
      <p class="title text-muted">STATUS</p>
      <p class="subtitle mb-1">${
        Names.status == true ? "ACTIVE" : "SUSPENDED"
      }</p>
      </div>`
      : ""
  }
 
  ${
    Names.consumer_class
      ? `<div class="row">
      <p class="title text-muted">CONSUMER CLASS</p>
      <p class="subtitle mb-1">${Names.consumer_class}</p>
      </div>`
      : ""
  }
 
  ${
    Names.number_of_flats
      ? `<div class="row">
      <p class="title text-muted">FLATS</p>
      <p class="subtitle mb-1">${Names.number_of_flats}</p>
      </div>`
      : ""
  }
 
  ${
    Names.number_of_households
      ? `<div class="row">
      <p class="title text-muted">HOUSEHOLD</p>
      <p class="subtitle mb-1">${Names.number_of_households}</p>
      </div>`
      : ""
  }
 
  ${
    Names.vroute_name
      ? `<div class="row">
      <p class="title text-muted">BOOK CODE</p>
      <p class="subtitle mb-1">${Names.vroute_name}</p>
      </div>`
      : ""
  }
 
  ${
    Names.transformer_name
      ? ` <div class="row">
      <p class="title text-muted">TRANSFORMER NAME</p>
      <p class="subtitle mb-1">${Names.transformer_name}</p>
      </div>`
      : ""
  }

  ${
    Names.feeder_name
      ? ` <div class="row">
      <p class="title text-muted">FEEDER NAME</p>
      <p class="subtitle mb-1">${Names.feeder_name}</p>
      </div>`
      : ""
  }

  ${
    Names.district_name
      ? `<div class="row">
      <p class="title text-muted">DISTRICT NAME</p>
      <p class="subtitle mb-1">${Names.district_name}</p>
      </div> `
      : ""
  }

  ${
    Names.ufirst_name
      ? `<div class="row">
      <p class="title text-muted">EMPLOYEES NAME</p>
      <p class="subtitle mb-1">${Names.ufirst_name}</p>
      </div>`
      : ""
  }

  ${
    Names.uphone
      ? `<div class="row">
      <p class="title text-muted">EMPLOYEES PHONE</p>
      <p class="subtitle mb-1">${Names.uphone}</p>
      </div>`
      : ""
  }

  ${
    Names.employee_id
      ? `<div class="row">
      <p class="title text-muted">EMPLOYEES ID</p>
      <p class="subtitle mb-1">${Names.employee_id}</p>
      </div>`
      : ""
  }

</div>`;
  $("#test").remove();
  return html;
}

function WFSURL(workspace, layer_name, cql_filter) {
  let Layer_cql_filter = cql_filter == "" ? "" : cql_filter
  // console.log(Layer_cql_filter);

  const url =
    geoserver_url +
    "geoserver/wfs?" +
    "service=WFS&" +
    "version=2.0.0&" +
    "request=GetFeature&" +
    "typename=" +
    workspace +
    ":" +
    layer_name +
    "&" +
    "outputFormat=application/json" +
    Layer_cql_filter +
    "&sortBy=t_district_id+ASC" +
    "&startIndex=0" +
    "&count=10";

  // console.log(url);
  return url;
}

export {
  receipt_counter,
  showBillsDetails,
  showReceiptsDetails,
  showVendingsDetails,
  indirectcard,
  ruralcard,
  generateCustomerDetails,
  indirect_counter,
  rural_counter,
  WFSURL,
};
