import map from "./map";
import {
  getBillAndPayments,
  indirectcard,
  indirect_parent_div,
  ruralcard,
  click_point_indirect_source,
  click_point_rural_source
} from "./bills";
import { bpDetailsParentDiv, bpParentDiv } from "./bills";
import Style from "ol/style/Style";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import { direct_source, direct_consumer_layer } from "./layer";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { baseurl } from "../config";
import { generateCustomerDetails, indirect_counter, rural_counter } from "./Function";
import { mylinechart } from "./charts";


var billdate = null;
var consumeraddress = null;
var consumername = null;
var consumernumber = null;
var billamount = null;
var empty_data_flag = false;

const Detailsparent = $("#nav-details");

function appendConsumerDetails(card) {
  Detailsparent.append(card);
}

var highlight_style = new Style({
  image: new CircleStyle({
    radius: 9,
    fill: new Fill({ color: "red" }),
    stroke: new Stroke({
      color: "orange",
      width: 4,
    }),
  }),
});

function consumer_details(consumer_no) {
  $.ajax({
    type: "GET",
    dataType: "json",
    cache: false,
    beforeSend: function () {
      $("#loader").addClass("loader");
    },
    url: baseurl + "report-service/api/v1/consumer/consumer_no/" + consumer_no,
  })
    .error(function (err) {
      console.log(err);
    })
    .done(function (data) {
      $("#indirect").empty()
      $("#loader").addClass("hide-loader");
      var direct = data.data.direct;
      var indirect = data.data.indirect;
      var rural = data.data.rural;
      if (direct != null) {
        const card = generateCustomerDetails(direct);
        appendConsumerDetails(card);
        } else {
          $.notify("No Direct Data Found", {
            color: "#fff",
            background: "#D44950",
            delay: 4000,
          });
        }

      if (indirect != "") {
        var indirect_count = indirect.length
        indirect_counter(indirect_count)
        indirectcard(indirect);
      } else {
        $.notify("No Indirect Data Found", {
          color: "#fff",
          background: "#D44950",
          delay: 8000,
        });
      }

      if (rural != "") {
        var rural_count = rural.length
        rural_counter(rural_count)
        ruralcard(rural);
      } else {
        $.notify("No Rural Data Found", {
          color: "#fff",
          background: "#D44950",
          delay: 6000,
        });
      }

      if (direct != null) {
        var name = direct.name == null ? "" : direct.name;
        consumername = name;
        var number = direct.consumer_no == null ? "" : direct.consumer_no;
        consumernumber = number;
        getBillAndPayments(consumernumber);
      } else {
        $.notify("No Direct Data Found", {
          color: "#fff",
          background: "#D44950",
          delay: 4000,
        });
      }

      if (direct == null && indirect == "" && rural == "") {
         Detailsparent.empty()
         bpDetailsParentDiv.empty();
         bpParentDiv.empty();
         indirect_parent_div.empty();
         if (mylinechart != null) {
          mylinechart.destroy()
         }
         empty_data_flag = true;
         $("#close").click()
      } else {
        empty_data_flag = false;
      }
    });
}

///////////////////////// state start /////////////////////////////////

const App = function _App() {
  return ``;
};

var global = false;
const change = function state() {
  if (global != true) {
    global = true;
  } else {
    global = false;
  }
};

document.getElementById("close").addEventListener("click", function (evt) {
  click_point_rural_source.clear()
  click_point_indirect_source.clear()
  highlight_source.clear();
  global = false;
});

App.state = {
  change,
};

const setState = (callback) => {
  callback();
  updateTree(); // extracted function
};

const updateTree = () => {
  document
    .getElementById("leftArrow")
    .addEventListener("click", App.state.change);
};

updateTree();
var state = { state: App.state.change };
///////////////// state end //////////////////////////////////

var highlight_source = new VectorSource({});
var highlight_layer = new VectorLayer({
  source: highlight_source,
  style: highlight_style,
  displayInLayerSwitcher: false,
});
map.addLayer(highlight_layer);

// var sidebar_global = false;
// document
//   .getElementById("Layer_Switcher_button")
//   .addEventListener("click", function (evt) {
//     if (sidebar_global == true) {
//       sidebar_global = false;
//     } else {
//       sidebar_global = true;
//     }
//   });

function details_on_click(evt,source) {
  const viewResolution = /** @type {number} */ (map.getView().getResolution());
  const url = source.getFeatureInfoUrl(
    evt.coordinate,
    viewResolution,
    "EPSG:3857",
    { INFO_FORMAT: "application/json" }
  );
  try {
    $.ajax({
      type: "GET",
      dataType: "json",
      url: url,
    }).done(function (data) {
      try {
      var consumer_no = data.features[0].properties.consumer_no;
      var coord = data.features;
      consumer_details(consumer_no);
      highlight_source.clear();
      var features = new GeoJSON().readFeatures(data);
      highlight_source.addFeatures(features);
      if (data.features[0].properties.connection_status == "Direct") {
        bpDetailsParentDiv.empty();
        bpParentDiv.empty();
        indirect_parent_div.empty();
        $("#indirect_count").remove();
        $("#rural_count").remove();
        $("#receipt_count").remove();
        if (global == false) {
            var offcanvas_tab = document.getElementById("leftArrow");
            $(offcanvas_tab).click();
            var details_tab = document.getElementById("nav-details-tab");
            $(details_tab).click();
        }
      } 
      } catch (error) {
        // console.log("Call error");
      }
    });
  } catch (error) {
    // console.log("Customerdetails error on click on customer");
  }
  // console.log(global);
}

map.on("click", function (evt) {
  var details_tab = document.getElementById("nav-details-tab");
  $(details_tab).click();
  // if (sidebar_global == true) {
  //   var sidebar_button = document.getElementById("Layer_Switcher_Closer");
  //   $(sidebar_button).click();
  //   sidebar_global = false;
  // } else {
  // }
  if (direct_consumer_layer.getVisible()) {
    details_on_click(evt,direct_source);
  } else {
  }
});


export {
  consumernumber,
  consumeraddress,
  billamount,
  billdate,
  showBox,
  click,
  details_on_click,
  direct_source,
  highlight_source,
  global
};
