import map from "./map";
import {
  substation,
  feeder,
  ht,
  dss,
  ltpole,
  lt_pole_view,
  direct_consumer_layer,
  rural_consumer_layer,
  indirect_consumer_layer,
  d2i,
  D2R,
  dss_source,
  direct_source,
  searched_consumer_layer,
  DFT_CQL_FILTER,
  DF_CQL_FILTER,
} from "./layer";
import { Show_clicked_layer_Source } from "./Label_Listener";
import { Overlay } from "ol";
import { WFSURL } from "./Function";
// import { Dft_Cql_Filter, Df_Cql_Filter } from "./count";

///////////////////////////////////////////////////////////////////

///////// overlay //////////

const container = document.getElementById("popup");
const content = document.getElementById("popup-content");
const closer = document.getElementById("popup-closer");

const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

map.addOverlay(overlay);

function Get_Layer_Feature(evt, source) {
  const viewResolution = /** @type {number} */ (map.getView().getResolution());
  const url = source.getFeatureInfoUrl(
    evt.coordinate,
    viewResolution,
    "EPSG:3857",
    { INFO_FORMAT: "application/json" }
  );

  $.ajax({
    type: "GET",
    dataType: "json",
    url: url,
  }).done(function (data) {
    var Feature = data.features[0];
    // console.log(Feature);
    if (Feature) {
      const coordinate = evt.coordinate;
      const featureProperties = Feature.properties;
      // console.log(featureProperties);
      let contentHTML = `<dl class="row mb-0">
      <table class="table-striped space-bottom0 marker-properties mt-2">`;

      // Iterate through the properties and add rows for each property
      for (const [key, value] of Object.entries(featureProperties)) {
        if (value !== null && value !== undefined && key !== "geometry") {
          contentHTML += `<tr><td>${key}</td><td>${value}</td></tr>`;
        }
      }

      contentHTML += `</table>`;

      content.innerHTML = contentHTML;
      overlay.setPosition(coordinate);
    } else {
      overlay.setPosition(undefined);
      content.innerHTML = "&nbsp;";
    }
  });
}

/////////////  overlay end  //////////////////////

///////////////////////////////////////////////////////////////////

let Dft_Cql_Filter =
  DFT_CQL_FILTER == "" ? "" : "&cql_filter=" + DFT_CQL_FILTER;

let Df_Cql_Filter = DFT_CQL_FILTER == "" ? "" : "&cql_filter=" + DF_CQL_FILTER;

var Subsation_layer_button = document.getElementById("INJECTION S/S");
var Feeder_layer_button = document.getElementById("FEEDER");
var Ht_pole_layer_button = document.getElementById("HT POLE");
var Dss_layer_button = document.getElementById("DSS");
var Lt_pole_layer_button = document.getElementById("LT POLE");
var Lt_pole_connection_line_layer_button =
  document.getElementById("LT POLE LINE");
var Direct_consumer_layer_button = document.getElementById("DIRECT CONSUMER");
var Rural_consumer_layer_button = document.getElementById("RURAL CONSUMER");
var Indirect_consumer_layer_button =
  document.getElementById("INDIRECT CONSUMER");
var D2I_layer_button = document.getElementById("D2I");
var D2R_layer_button = document.getElementById("D2R");
var Search_consumer_layer_button = document.getElementById("SEARCH CONSUMER");

var geoserver_workspace = "new_gis_testing_workspace";

var direct_layer_name_in_geoserver = "matched_db";
var indirect_layer_name_in_geoserver = "indirect_consumer";
var rural_layer_name_in_geoserver = "rural_consumers";
var dss_layer_name_in_geoserver = "dss";
var substation_layer_name_in_geoserver = "injection_substation";
var htpole_layer_name_in_geoserver = "ht_pole";
var ltpole_layer_name_in_geoserver = "lt_pole";
var feeder_layer_name_in_geoserver = "feeder_line";
var lt_pole_connecting_lines_name_geoserver = "lt_pole_connecting_lines";
var d2i_layer_name_in_geoserver = "direct_to_indirect_connection";
var d2r_layer_name_in_geoserver = "direct_to_rural_connection";
var input = document.getElementById("Tool_box_input");

const dss_img = document.getElementById("legend");
const direct_img = document.getElementById("direct-legend");
const Tool_Box_Legend = document.getElementById("Tool_box_legend");

// var Basic_CQL_Filter =
//   t_district_id(dist_id) +
//   get_feeder_by_url_for_all_in_one_layer +
//   get_transformer_by_url_for_all_in_one_layer;

// var Substation_cql_filter =
//   t_district_id(dist_id) + get_feeder_by_url_for_all_in_one_layer;

// var Feeder_cql_filter =
//   t_district_id(dist_id) + get_feeder_by_url_for_all_in_one_layer;

// var Ht_pole_cql_filter =
//   t_district_id(dist_id) + get_feeder_by_url_for_all_in_one_layer;

Direct_consumer_layer_button.checked = true;

Subsation_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Subsation_layer_button.checked) {
    var Substation_url = WFSURL(
      geoserver_workspace,
      substation_layer_name_in_geoserver,
      Df_Cql_Filter
    );
    // console.log(Df_Cql_Filter);
    fetch(Substation_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          substation.setVisible(true);
        } else {
          $.notify("No Data Found In Substation", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    substation.setVisible(false);
  }
});

Feeder_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Feeder_layer_button.checked) {
    var Feeder_url = WFSURL(
      geoserver_workspace,
      feeder_layer_name_in_geoserver,
      Df_Cql_Filter
    );
    fetch(Feeder_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          feeder.setVisible(true);
        } else {
          $.notify("No Data Found In Feeder Line", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    feeder.setVisible(false);
  }
});

Ht_pole_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Ht_pole_layer_button.checked) {
    var Ht_pole__url = WFSURL(
      geoserver_workspace,
      htpole_layer_name_in_geoserver,
      Df_Cql_Filter
    );
    fetch(Ht_pole__url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          ht.setVisible(true);
        } else {
          $.notify("No Data Found In HT Pole", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    ht.setVisible(false);
  }
});

Dss_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Dss_layer_button.checked) {
    var DSS_url = WFSURL(
      geoserver_workspace,
      dss_layer_name_in_geoserver,
      Dft_Cql_Filter
    );
    fetch(DSS_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          if (!direct_consumer_layer.getVisible()) {
            Tool_Box_Legend.style.border = "0.5px solid #0e918c";
            Tool_Box_Legend.style.backgroundColor = "#0e918c";
          }
          const dss_updateLegend = function (resolution) {
            const graphicUrl = dss_source.getLegendUrl(resolution);
            dss_img.src = graphicUrl;
          };
          const resolution = map.getView().getResolution();
          dss_updateLegend(resolution);
          dss.setVisible(true);
        } else {
          $.notify("No Data Found In DSS", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
          Tool_Box_Legend.disabled = true;
          Tool_Box_Legend.style.border = "0.5px solid white";
          Tool_Box_Legend.style.backgroundColor = "white";
        }
      });
  } else {
    if (!direct_consumer_layer.getVisible()) {
      // console.log("Direct Layer Is Not Visible");
      Tool_Box_Legend.style.border = "0.5px solid white";
      Tool_Box_Legend.style.backgroundColor = "white";
    }
    const dss_img = document.getElementById("legend");
    dss_img.src = "";
    dss.setVisible(false);
  }
});

Lt_pole_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Lt_pole_layer_button.checked) {
    var Lt_pole_url = WFSURL(
      geoserver_workspace,
      ltpole_layer_name_in_geoserver,
      Dft_Cql_Filter
    );
    fetch(Lt_pole_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          ltpole.setVisible(true);
        } else {
          $.notify("No Data Found In LT Pole", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    ltpole.setVisible(false);
  }
});

Lt_pole_connection_line_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Lt_pole_connection_line_layer_button.checked) {
    var Lt_pole_connecting_lines_url = WFSURL(
      geoserver_workspace,
      lt_pole_connecting_lines_name_geoserver,
      Dft_Cql_Filter
    );
    fetch(Lt_pole_connecting_lines_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          lt_pole_view.setVisible(true);
        } else {
          $.notify("No Data Found In Lt Pole Line", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    lt_pole_view.setVisible(false);
  }
});

Direct_consumer_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Direct_consumer_layer_button.checked) {
    var Direct_url = WFSURL(
      geoserver_workspace,
      direct_layer_name_in_geoserver,
      Dft_Cql_Filter
    );
    fetch(Direct_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          if (!direct_consumer_layer.getVisible()) {
            Tool_Box_Legend.style.border = "0.5px solid #0e918c";
            Tool_Box_Legend.style.backgroundColor = "#0e918c";
          }
          const direct_updateLegend = function (resolution) {
            const graphicUrl = direct_source.getLegendUrl(resolution);
            direct_img.src = graphicUrl;
          };
          const resolution1 = map.getView().getResolution();
          direct_updateLegend(resolution1);
          direct_consumer_layer.setVisible(true);
        } else {
          $.notify("No Data Found In Direct Consumer", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
          Tool_Box_Legend.disabled = true;
          Tool_Box_Legend.style.border = "0.5px solid white";
          Tool_Box_Legend.style.backgroundColor = "white";
        }
      });
  } else {
    if (!dss.getVisible()) {
      // console.log("DSS Is Not Visible");
      Tool_Box_Legend.style.border = "0.5px solid white";
      Tool_Box_Legend.style.backgroundColor = "white";
    }
    const direct_img = document.getElementById("direct-legend");
    direct_img.src = "";
    direct_consumer_layer.setVisible(false);
  }
});

Rural_consumer_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Rural_consumer_layer_button.checked) {
    var Rural_url = WFSURL(
      geoserver_workspace,
      rural_layer_name_in_geoserver,
      Dft_Cql_Filter
    );
    fetch(Rural_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          rural_consumer_layer.setVisible(true);
        } else {
          $.notify("No Data Found In Rural Consumer", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    rural_consumer_layer.setVisible(false);
  }
});

Indirect_consumer_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (Indirect_consumer_layer_button.checked) {
    var Indirect_url = WFSURL(
      geoserver_workspace,
      indirect_layer_name_in_geoserver,
      Dft_Cql_Filter
    );
    fetch(Indirect_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          indirect_consumer_layer.setVisible(true);
        } else {
          $.notify("No Data Found In Indirect Consumer", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    indirect_consumer_layer.setVisible(false);
  }
});

D2I_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (D2I_layer_button.checked) {
    var D2I_url = WFSURL(
      geoserver_workspace,
      d2i_layer_name_in_geoserver,
      Dft_Cql_Filter
    );
    fetch(D2I_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          d2i.setVisible(true);
        } else {
          $.notify("No Data Found In D2i", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    d2i.setVisible(false);
  }
});

D2R_layer_button.addEventListener("change", function () {
  Show_clicked_layer_Source.clear();
  if (D2R_layer_button.checked) {
    var D2R_url = WFSURL(
      geoserver_workspace,
      d2r_layer_name_in_geoserver,
      Dft_Cql_Filter
    );
    fetch(D2R_url)
      .then((response) => response.text())
      .then((data) => {
        var Features = JSON.parse(data).features;
        if (Features.length > 0) {
          D2R.setVisible(true);
        } else {
          $.notify("No Data Found In D2R", {
            color: "#fff",
            background: "#D44950",
            delay: 3000,
          });
        }
      });
  } else {
    D2R.setVisible(false);
  }
});

Search_consumer_layer_button.addEventListener("change", function () {
  if (Search_consumer_layer_button.checked) {
    searched_consumer_layer.setVisible(true);
  } else {
    searched_consumer_layer.setVisible(false);
  }
});

input.addEventListener("input", function () {
  const isEmpty = input.value.trim() === "";
  if (isEmpty) {
    map.getLayers().forEach(function (layer) {
      if (layer.get("title") == "SEARCHED CONSUMER") map.removeLayer(layer);
      document.querySelector(".search_consumer").style.display = "none";
      document.getElementById("SEARCH CONSUMER").checked = false;
      Direct_consumer_layer_button.checked = true;
      direct_consumer_layer.setVisible(true);
      Tool_Box_Legend.style.border = "0.5px solid #0e918c";
      Tool_Box_Legend.style.backgroundColor = "#0e918c";
    });
  }
});

// Adding a click event listener to the map
map.on("click", function (event) {
  if (Subsation_layer_button.checked == true) {
    Get_Layer_Feature(event, substation.getSource());
  }

  if ($("#FEEDER").is(":checked")) {
    Get_Layer_Feature(event, feeder.getSource());
  }

  if (Ht_pole_layer_button.checked == true) {
    Get_Layer_Feature(event, ht.getSource());
  }

  if ($("#DSS").is(":checked")) {
    Get_Layer_Feature(event, dss.getSource());
  }

  if (Lt_pole_layer_button.checked == true) {
    Get_Layer_Feature(event, ltpole.getSource());
  }

  if (Lt_pole_connection_line_layer_button.checked == true) {
    Get_Layer_Feature(event, lt_pole_view.getSource());
  }

  if (Rural_consumer_layer_button.checked == true) {
    Get_Layer_Feature(event, rural_consumer_layer.getSource());
  }

  if (Indirect_consumer_layer_button.checked == true) {
    Get_Layer_Feature(event, indirect_consumer_layer.getSource());
  }

  if (D2I_layer_button.checked == true) {
    Get_Layer_Feature(event, d2i.getSource());
  }

  if (D2R_layer_button.checked == true) {
    Get_Layer_Feature(event, D2R.getSource());
  }
});
