import {
  // t_district_id,
  // get_feeder_by_url_for_all_in_one_layer,
  // get_transformer_by_url_for_all_in_one_layer,
  dist_id,
  feeder_id,
  transformer_id,
  bym,
  DFT_CQL_FILTER,
  DF_CQL_FILTER
} from "./layer";
import { geoserver_url, baseurl } from "../config";
import GeoJSON from "ol/format/GeoJSON.js";

let Dft_Cql_Filter = DFT_CQL_FILTER == "" ? "" : "&cql_filter=" + DFT_CQL_FILTER 

let Df_Cql_Filter = DFT_CQL_FILTER == "" ? "" : "&cql_filter=" + DF_CQL_FILTER 

// var base_cql_filter =
//   t_district_id(dist_id) +
//   get_feeder_by_url_for_all_in_one_layer +
//   get_transformer_by_url_for_all_in_one_layer;

// var end_feeder_filter =
//   t_district_id(dist_id) + get_feeder_by_url_for_all_in_one_layer;

var geoserver_workspace = "new_gis_testing_workspace:";

var direct_layer_name_in_geoserver = "matched_db";
var indirect_layer_name_in_geoserver = "indirect_consumer";
var rural_layer_name_in_geoserver = "rural_consumers";
var dss_layer_name_in_geoserver = "dss";
var substation_layer_name_in_geoserver = "injection_substation";
var htpole_layer_name_in_geoserver = "ht_pole";
var ltpole_layer_name_in_geoserver = "lt_pole";
var feeder_layer_name_in_geoserver = "feeder_line";

const direct_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  direct_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Dft_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

const indirect_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  indirect_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Dft_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

const rural_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  rural_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Dft_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

const dss_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  dss_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Dft_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

const substation_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  substation_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Df_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

const htpole_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  htpole_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Df_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

const ltpole_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  ltpole_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Dft_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

const feeder_url =
  geoserver_url +
  "geoserver/wfs?" +
  "service=WFS&" +
  "version=2.0.0&" +
  "request=GetFeature&" +
  "typeName=" +
  geoserver_workspace +
  feeder_layer_name_in_geoserver +
  "&" +
  "outputFormat=application/json&" +
  "srsname=EPSG:4326" +
  Df_Cql_Filter +
  "&sortBy=t_district_id+ASC" +
  "&startIndex=0" +
  "&count=10";

async function api_call(url) {
  // $("#count_load").addClass("loader_count");
  $("#injection").addClass("loading");
  $("#dss").addClass("loading");
  $("#htpole").addClass("loading");
  $("#ltpole").addClass("loading");
  $("#directconsumer").addClass("loading");
  $("#ruralconsumer").addClass("loading");
  $("#indirectconsumer").addClass("loading");

  const response = await fetch(url);
  const data = await response.json();
  // const featureCount = data.features.length;
  const featureCount = data.totalFeatures;

  // $("#count_load").removeClass("loader_count");
  $("#injection").removeClass("loading");
  $("#dss").removeClass("loading");
  $("#htpole").removeClass("loading");
  $("#ltpole").removeClass("loading");
  $("#directconsumer").removeClass("loading");
  $("#ruralconsumer").removeClass("loading");
  $("#indirectconsumer").removeClass("loading");

  return featureCount;
}

async function direct_usage() {
  const substation_featureCount = await api_call(substation_url);
  const dss_featureCount = await api_call(dss_url);
  const htpole_featureCount = await api_call(htpole_url);
  const ltpole_featureCount = await api_call(ltpole_url);
  const direct_featureCount = await api_call(direct_url);
  const rural_featureCount = await api_call(rural_url);
  const indirect_featureCount = await api_call(indirect_url);
  $("#injection").append(
    `${
      substation_featureCount == null || undefined
        ? "-"
        : substation_featureCount
    }`
  );
  $("#dss").append(
    `${dss_featureCount == null || undefined ? "-" : dss_featureCount}`
  );
  $("#htpole").append(
    `${htpole_featureCount == null || undefined ? "-" : htpole_featureCount}`
  );
  $("#ltpole").append(
    `${ltpole_featureCount == null || undefined ? "-" : ltpole_featureCount}`
  );
  $("#directconsumer").append(
    `${direct_featureCount == null || undefined ? "-" : direct_featureCount}`
  );
  $("#ruralconsumer").append(
    `${rural_featureCount == null || undefined ? "-" : rural_featureCount}`
  );
  $("#indirectconsumer").append(
    `${
      indirect_featureCount == null || undefined ? "-" : indirect_featureCount
    }`
  );
}

const Add_Loader = () => {
  $("#totalconsumer").addClass("loading");
  $("#postpaid").addClass("loading");
  $("#prepaid").addClass("loading");
  $("#standalone").addClass("loading");
  $("#billedcustomer").addClass("loading");
  $("#billedarrears").addClass("loading");
  $("#paidcustomer").addClass("loading");
  $("#unpaidcustomer").addClass("loading");
  $("#vendingcustomer").addClass("loading");
};

const Remove_Loader = () => {
  $("#totalconsumer").removeClass("loading");
  $("#postpaid").removeClass("loading");
  $("#prepaid").removeClass("loading");
  $("#standalone").removeClass("loading");
  $("#billedcustomer").removeClass("loading");
  $("#billedarrears").removeClass("loading");
  $("#paidcustomer").removeClass("loading");
  $("#unpaidcustomer").removeClass("loading");
  $("#vendingcustomer").removeClass("loading");
};

const Append_Zero = () => {
  $("#totalconsumer").append("-");
  $("#postpaid").append("-");
  $("#prepaid").append("-");
  $("#standalone").append("-");
  $("#billedcustomer").append("-");
  $("#billedarrears").append("-");
  $("#paidcustomer").append("-");
  $("#unpaidcustomer").append("-");
  $("#vendingcustomer").append("-");
};

if (transformer_id) {
  var collection = {
    name: "COLLECTION_DONE",
  };

  $.ajax({
    type: "POST",
    data: collection,
    dataType: "json",
    url:
      baseurl +
      "report-service/api/v1/event/collection/summary-dashboard?" +
      "bym=" +
      bym +
      "&_fkey=ALL&dss_id=" +
      transformer_id,
    beforeSend: function () {
      Add_Loader();
    },
  }).done(function (data) {
      Remove_Loader();
      // console.log(response.data,"transformer id");
      var data = data.data;
      var unpaidcustomers =
        Number(data.billed_consumers) - Number(data.receipt_paid_count);
      $("#totalconsumer").append(
        `${
          data.total_customers == null || undefined ? "-" : data.total_customers
        }`
      );
      $("#postpaid").append(
        `${
          data.postpaid_total == null || undefined ? "-" : data.postpaid_total
        }`
      );
      $("#prepaid").append(
        `${data.prepaid_total == null || undefined ? "-" : data.prepaid_total}`
      );
      $("#standalone").append(
        `${
          data.standalone_total == null || undefined
            ? "-"
            : data.standalone_total
        }`
      );
      $("#billedcustomer").append(
        `${
          data.billed_consumers == null || undefined
            ? "-"
            : data.billed_consumers
        }`
      );
      $("#billedarrears").append(
        `${
          data.bill_arrears == null || undefined
            ? "-"
            : data.bill_arrears.toFixed(2)
        }`
      );
      $("#paidcustomer").append(
        `${
          data.receipt_paid_count == null || undefined
            ? "-"
            : data.receipt_paid_count
        }`
      );
      $("#unpaidcustomer").append(
        `${unpaidcustomers == null || undefined ? "-" : unpaidcustomers}`
      );
      $("#vendingcustomer").append(
        `${
          data.vending_paid_count == null || undefined
            ? "-"
            : data.vending_paid_count
        }`
      );
      // $("#non-vendingcustomer").append(`${
      //   data.prepaid_total == null || undefined ? "-" : data.prepaid_total
      // }`);
    })
    .fail(function (xhr, textStatus, errorThrown) {
      $.notify(xhr.responseText, {
        color: "#fff",
        background: "#D44950",
        delay: 5000,
      });
      Remove_Loader();
      Append_Zero()
    });
} else if (feeder_id) {
  var collection = {
    name: "COLLECTION_DONE",
  };

  $.ajax({
    type: "POST",
    data: collection,
    dataType: "json",
    url:
      baseurl +
      "report-service/api/v1/event/collection/summary-dashboard?" +
      "bym=" +
      bym +
      "&_fkey=ALL&fd_id=" +
      feeder_id,
    beforeSend: function () {
      Add_Loader();
    },
  }).done(function (data) {
      Remove_Loader();
      var data = data.data;
      var unpaidcustomers =
        Number(data.billed_consumers) - Number(data.receipt_paid_count);

      $("#totalconsumer").append(
        `${
          data.total_customers == null || undefined ? "-" : data.total_customers
        }`
      );
      $("#postpaid").append(
        `${
          data.postpaid_total == null || undefined ? "-" : data.postpaid_total
        }`
      );
      $("#prepaid").append(
        `${data.prepaid_total == null || undefined ? "-" : data.prepaid_total}`
      );
      $("#standalone").append(
        `${
          data.standalone_total == null || undefined
            ? "-"
            : data.standalone_total
        }`
      );
      $("#billedcustomer").append(
        `${
          data.billed_consumers == null || undefined
            ? "-"
            : data.billed_consumers
        }`
      );
      $("#billedarrears").append(
        `${
          data.bill_arrears == null || undefined
            ? "-"
            : data.bill_arrears.toFixed(2)
        }`
      );
      $("#paidcustomer").append(
        `${
          data.receipt_paid_count == null || undefined
            ? "-"
            : data.receipt_paid_count
        }`
      );
      $("#unpaidcustomer").append(
        `${unpaidcustomers == null || undefined ? "-" : unpaidcustomers}`
      );
      $("#vendingcustomer").append(
        `${
          data.vending_paid_count == null || undefined
            ? "-"
            : data.vending_paid_count
        }`
      );
      // $("#non-vendingcustomer").append(`${
      //   data.prepaid_total == null || undefined ? "-" : data.prepaid_total
      // }`);
    })
    .fail(function (xhr, textStatus, errorThrown) {
      $.notify(xhr.responseText, {
        color: "#fff",
        background: "#D44950",
        delay: 5000,
      });
      Remove_Loader();
      Append_Zero()
    });
} else if (dist_id) {
  var collection = {
    name: "COLLECTION_DONE",
  };

  $.ajax({
    type: "POST",
    data: collection,
    dataType: "json",
    url:
      baseurl +
      "report-service/api/v1/event/collection/summary-dashboard?" +
      "bym=" +
      bym +
      "&_fkey=ALL&dist_id=" +
      dist_id,
    beforeSend: function () {
      Add_Loader();
    },
  }).done(function (data) {
      Remove_Loader();
      var data = data.data;
      var unpaidcustomers =
        Number(data.billed_consumers) - Number(data.receipt_paid_count);

      $("#totalconsumer").append(
        `${
          data.total_customers == null || undefined ? "-" : data.total_customers
        }`
      );
      $("#postpaid").append(
        `${
          data.postpaid_total == null || undefined ? "-" : data.postpaid_total
        }`
      );
      $("#prepaid").append(
        `${data.prepaid_total == null || undefined ? "-" : data.prepaid_total}`
      );
      $("#standalone").append(
        `${
          data.standalone_total == null || undefined
            ? "-"
            : data.standalone_total
        }`
      );
      $("#billedcustomer").append(
        `${
          data.billed_consumers == null || undefined
            ? "-"
            : data.billed_consumers
        }`
      );
      $("#billedarrears").append(
        `${
          data.bill_arrears == null || undefined
            ? "-"
            : data.bill_arrears.toFixed(2)
        }`
      );
      $("#paidcustomer").append(
        `${
          data.receipt_paid_count == null || undefined
            ? "-"
            : data.receipt_paid_count
        }`
      );
      $("#unpaidcustomer").append(
        `${unpaidcustomers == null || undefined ? "-" : unpaidcustomers}`
      );
      $("#vendingcustomer").append(
        `${
          data.vending_paid_count == null || undefined
            ? "-"
            : data.vending_paid_count
        }`
      );
      // $("#non-vendingcustomer").append(`${
      //   data.prepaid_total == null || undefined ? "-" : data.prepaid_total
      // }`);
    })
    .fail(function (xhr, textStatus, errorThrown) {
      $.notify(xhr.responseText, {
        color: "#fff",
        background: "#D44950",
        delay: 5000,
      });
      Remove_Loader();
      Append_Zero()
    });
}

direct_usage();

export {
  Dft_Cql_Filter,
  Df_Cql_Filter
}