import moment from "moment";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import { Vector as VectorSource, Stamen as SourceStamen, XYZ } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import { transform } from "ol/proj";
import map from "./map";
import { View } from "ol";
import {
  indirect_consumer_layer,
  rural_consumer_layer,
  Satellite,
} from "./layer";
import Feature from "ol/Feature.js";
import { showBillAndPaymentChart } from "./charts";
import { baseurl } from "../config";
import {
  receipt_counter,
  showBillsDetails,
  showReceiptsDetails,
  showVendingsDetails,
  indirectcard,
  ruralcard
} from "./Function";
import { Point } from "ol/geom";
import { satellite_layer_flag } from "./layer";

let billAndPaymentDetails = {};

const bpParentDiv = $("#parent-payment-div");
const bpDetailsParentDiv = $("#list-div");
const indirect_parent_div = $("#indirect");
var date = moment().format("MMM-YY");

bpParentDiv.on("click", ".bp_card", function (e) {
  //do something
  // console.log(e.attr("id"));
  const element = $(this);
  const cno = element.data("cno");
  const bym = element.data("bym");
  const bills = billAndPaymentDetails.bill[bym];
  const receipts = billAndPaymentDetails.receipt[bym];
  const vendings = billAndPaymentDetails.vending[bym];
  showBPDetails(bills, receipts, vendings);
});

var click_point_indirect_style = new Style({
  image: new CircleStyle({
    radius: 9,
    fill: new Fill({ color: "transparent" }),
    stroke: new Stroke({
      color: "blue",
      width: 4,
    }),
  }),
});

var click_point_indirect_source = new VectorSource({});
var click_point_indirect_layer = new VectorLayer({
  source: click_point_indirect_source,
  style: click_point_indirect_style,
  displayInLayerSwitcher: false,
});

var click_point_rural_style = new Style({
  image: new CircleStyle({
    radius: 9,
    fill: new Fill({ color: "transparent" }),
    stroke: new Stroke({
      color: "#705558",
      width: 4,
    }),
  }),
});

var click_point_rural_source = new VectorSource({});
var click_point_rural_layer = new VectorLayer({
  source: click_point_rural_source,
  style: click_point_rural_style,
  displayInLayerSwitcher: false,
});

const genBillAndPaymentHistoryCards = (data) => {
  if (data && Array.isArray(data)) {
    $("#parent-payment-div").empty();
    data.forEach((hd) => {
      addBPCard(genBPCard(hd));
    });
  } else {
    console.log("no bill payment data found");
  }
};

function addBPCard(card) {
  bpParentDiv.append(card);
}

function getBillAndPayments(consumernumber) {
  $.ajax({
    type: "GET",
    dataType: "json",
    url:
      baseurl +
      "report-service/api/v1/historical/bill-payment?consumer_no=" +
      consumernumber +
      "&bill_year_month=" +
      date,
  }).done(function (data) {
    if (data.code == 201) {
      billAndPaymentDetails = data.data;
      var receipts = billAndPaymentDetails.receipt;
      if (receipts != "") {
        receipt_counter(receipts);
      }
      const chartData = billAndPaymentDetails.chart;
      showBillAndPaymentChart(
        chartData.x,
        chartData.y["amount"],
        chartData.y["bill_amount"]
      );
      var historyData = data.data.history;
      genBillAndPaymentHistoryCards(historyData);
    }
  });
}

function showBPDetails(bills, receipts, vendings) {
  document.getElementById("nav-hidden-tab").click();

  if (bills) {
    showBillsDetails(bills);
  }
  if (receipts) {
    showReceiptsDetails(receipts);
  }
  if (vendings) {
    showVendingsDetails(vendings);
  }
}

function genBPCard(data) {
  let Names = data;
  const html = `<div class="card shadow mb-2 mt-1 bp_card" data-cno="${
    Names.consumer_no
  }"
    data-bym="${
      Names.bill_year_month == null ? " -" : `${Names.bill_year_month}`
    }" id="month-card">
    <div class="payment-card-body">
        <div class="row">
            <div class="col-4">
                <div class="payment-card-date">${
                  Names.bill_year_month == null
                    ? "-"
                    : `${Names.bill_year_month}`
                }
                </div>
                <div class="payment-card-date1">${
                  Names.bill_amount == null
                    ? "-"
                    : `${Names.bill_amount.toFixed(2)}`
                }
                </div>
            </div>
            <div class="col-8">
                <div class="d-flex justify-content-between vl1">
                    <div class="item1">
                        <span class="payment-text">Paid</span>
                        <span class="caption2">${
                          Names.amount
                            ? `<span
                                class="text-muted">&#8358</span>&nbsp;${Names.amount}</span>`
                            : "-"
                        }
                    </div>
                    <div class="item1">
                        <span class="payment-text">Balance</span>
                        <span class="caption2">${
                          Names.balance
                            ? `<span class="text-muted">&#8358
                            </span>&nbsp;${Names.balance.toFixed(2)}</span>`
                            : "-"
                        }
                    </div>
                    <div class="item1">
                        <span class="text-muted payment-date">${
                          Names.latest_payment_date
                            ? moment(Names.latest_payment_date).format(
                                "DD/MM/YYYY"
                              )
                            : ""
                        }</span>
                        ${
                          Names.count
                            ? `<span class="payment-circle">${Names.count}</span>`
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`;
  return html;
}

map.addLayer(click_point_indirect_layer);

function view_of_point(center_lat_lon) {
  var view = new View({
    center: center_lat_lon,
    zoom: 22,
    maxZoom: 18,
  });
  map.setView(view);
}

$(document).on("click", ".loc", function () {
  // console.log(satellite_layer_flag);
  var point_coords = $(this).closest("div").find(".indirect")[0].textContent;
  var point_data = JSON.parse(point_coords);
  var center_lat_lon = fromLonLat(point_data);
  var indirect_layer_visibility = indirect_consumer_layer.getVisible();
  if (indirect_layer_visibility == true) {
    var marker = new Feature({
      geometry: new Point(transform(point_data, "EPSG:4326", "EPSG:3857")),
    });
    click_point_indirect_source.clear();
    click_point_indirect_source.addFeature(marker);
    if (satellite_layer_flag == true) {
      view_of_point(center_lat_lon);
    } else if (satellite_layer_flag == false) {
      map.setView(
        new View({
          center: center_lat_lon,
          zoom: 22,
        })
      );
    }
  } else {
    click_point_indirect_source.clear();
    $.notify(
      "Indirect Layer Is Not Selected",
      { color: "#fff", background: "#D44950" },
      { delay: 1000 }
    );
  }
});

map.addLayer(click_point_rural_layer);

$(document).on("click", ".loc2", function () {
  var point_coords = $(this).closest("div").find(".rural")[0].textContent;
  var point_data = JSON.parse(point_coords);
  var center_lat_lon = fromLonLat(point_data);
  var rural_layer_visibility = rural_consumer_layer.getVisible();
  if (rural_layer_visibility == true) {
    var marker = new Feature({
      geometry: new Point(transform(point_data, "EPSG:4326", "EPSG:3857")),
    });
    click_point_rural_source.clear();
    click_point_rural_source.addFeature(marker);
    if (satellite_layer_flag == true) {
      view_of_point(center_lat_lon);
    } else if (satellite_layer_flag == false) {
      map.setView(
        new View({
          center: center_lat_lon,
          zoom: 22,
        })
      );
    }
  } else {
    click_point_rural_source.clear();
    $.notify(
      "Rural Layer Is Not Selected",
      { color: "#fff", background: "#D44950" },
      { delay: 1000 }
    );
  }
});

export {
  getBillAndPayments,
  bpDetailsParentDiv,
  bpParentDiv,
  indirectcard,
  indirect_parent_div,
  ruralcard,
  click_point_indirect_source,
  click_point_rural_source,
};
