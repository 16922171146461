var baseurl = "https://topupp.i-disco.in/";
var geoserver_url= "https://gis.i-disco.in/"
var image_url = "https://gis.i-disco.in/img/"
var kpi_report_url = "https://topupp.i-disco.in/reports/pages/kpi-report"
var activity_report_url = "https://topupp.i-disco.in/activityreport/pages/activity-report"
var employee_image_url = 'https://topupp.i-disco.in/uploads/c200%C3%97200/'
var Feeder_service_Url = "https://energy.i-disco.in/";
var Trasformer_Service_Url ="https://transformer.i-disco.in/transformers-service/";

export { baseurl , geoserver_url , kpi_report_url , activity_report_url ,image_url, employee_image_url, Feeder_service_Url, Trasformer_Service_Url};
